    /* You can add global styles to this file, and also import other style files */

    /*==============================
    Default CSS
    ==============================*/
    @font-face {
        font-family: 'Arial';
        src: url('assets/fonts/arial_bold.ttf');

    }
    body {
        font-family: 'Arial Light', sans-serif;        
        width: 100%;
        height: 100%;
    }
    
    :root {
        --font-family: 'Arial', sans-serif;
        --font-light:'Arial', Light
        --mainColor: #3A9B43;
        --whiteColor: #ffffff;
        --blackColor: #000000;
        --transition: .5s;
    }
    body {
        font-weight: regular; /* Aplicar peso de fuente bold */
        padding: 0;
        margin: 0;
        background-color: #000000;
    }
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        font-family: Arial, sans-serif; /* Usar Arial como fuente principal */
        margin-bottom: 0;
        font-weight: bold !important; /* Aplicar peso de fuente bold */
        color: var(--blackColor);
        line-height: 1;
    }
    .titulo-grande{
        margin-bottom: 0;
        font-weight: bold !important; /* Aplicar peso de fuente bold */
        color: var(--blackColor);
        line-height: 1;
        font-size: 44px !important;
    }
    .titulo-chico{
        margin-bottom: 0;
        font-weight: bold !important; /* Aplicar peso de fuente bold */
        color: var(--blackColor);
        line-height: 1;
        font-size: 36px !important;
    }
    .texto-grande{
        margin-bottom: 0;
        color: var(--blackColor);
        line-height: 1;
        font-size: 20px !important;
    }
    .texto-chico{
        margin-bottom: 0;
        color: var(--blackColor);
        line-height: 1;
        font-size: 16px !important;
    }
    .letra-bold{
        font-family: Arial, sans-serif; /* Usar Arial como fuente principal */
        font-weight: bold; /* Aplicar peso de fuente bold */

    }
    .letra-light{
        font-family: Arial, sans-serif !important; /* Usar Arial como fuente principal */
        font-weight: lighter !important; /* Aplicar peso de fuente bold */

    }
    
    ul {
        margin-bottom: 0;
        li {
            list-style: none;
        }
    }
    :focus {
        outline: 0 !important;
    }
    p {
        
        color: #000000;
    }
    a {
        cursor: pointer;
        color: #000000;
        text-decoration: none;
        transition: var(--transition);

        &:hover, &:focus {
            color: var(--blackColor);
            text-decoration: none;
        }
    }
    .interlineado{
        line-height: 1.8;
    }
    img {
        max-width: 100%;
    }
    .ptb-100 {
        padding: {
            bottom: 100px;
            top: 100px;
        }
    }
    .pt-100 {
        padding: {
            top: 100px;
        }
    }
    .pb-100 {
        padding: {
            bottom: 100px;
        }
    }
    .ptb-70 {
        padding: {
            top: 70px;
            bottom: 70px;
        }
    }
    .pt-70 {
        padding: {
            top: 70px;
        }
    }
    .pb-70 {
        padding: {
            bottom: 70px;
        }
    }
    .d-table {
        width: 100%;
        height: 100%;

        &-cell {
            vertical-align: middle;
        }
    }
    .bg-fffdfd {
        background: #fffdfd;
    }
    .bg-0B1F0D {
        background: #0B1F0D;
    }
    .bg-F7F5F4 {
        background-color: #F7F5F4;
    }
    .ml-auto {
        margin-left: auto;
    }
    /*Btn Btn-Primary*/
    .btn {
        padding: 5px 5px;
        transition: var(--transition);
        font: {
            size: 16px;
            weight: 500;
        };
    }
    .btns {
        border-radius: 30px;
        transition: var(--transition);
        font: {
            size: 16px;
            weight: 100;
        };
    }
    .btn-primary {
        color: var(--whiteColor);
        background-color: white;
        border-color: var(--mainColor);
        position: static;
        margin-left: 0;
        height: 3em;
        
        
        &:hover, &:focus, &.disabled:hover, &.disabled:focus {
            color: var(--mainColor);
            background-color: white;
            border-color: rgb(255, 255, 255);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
        
        &.focus, &:focus {
            box-shadow: none;
        }
        
        &.disabled, &:disabled {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
            opacity: 1;
        }
    } 
    .btn-byd {
        color: black;
        background-color: #FFCC00;
        border-color: #FFCC00;
        position: static;
        margin-left: 0;
        top: 60%;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        
        
        &:hover, &:focus, &.disabled:hover, &.disabled:focus {
            color: var(--mainColor);
            background-color: var(--whiteColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
        
        &.focus, &:focus {
            box-shadow: none;
        }
        
        &.disabled, &:disabled {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
            opacity: 1;
        }
    } 
    .btn-byd4 {
        color: black;
        background-color: #F0F0F0;
        border-color: #F0F0F0;
        position: static;
        margin-left: 0;
        top: 60%;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        
        
        &:hover, &:focus, &.disabled:hover, &.disabled:focus {
            color: var(--mainColor);
            background-color: var(--whiteColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
        
        &.focus, &:focus {
            box-shadow: none;
        }
        
        &.disabled, &:disabled {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
            opacity: 1;
        }
    } 
    .btn-byd2 {
        color: black;
        background-color: #5EBAAB;
        border-color: #5EBAAB;
        position: static;
        margin-left: 0;
        top: 60%;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        
        
        &:hover, &:focus, &.disabled:hover, &.disabled:focus {
            color: var(--mainColor);
            background-color: var(--whiteColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
        
        &.focus, &:focus {
            box-shadow: none;
        }
        
        &.disabled, &:disabled {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
            opacity: 1;
        }
    } 
    .btn-byd3 {
        color: black;
        background-color: #FF5A00;
        border-color: #FF5A00;
        position: static;
        margin-left: 0;
        top: 60%;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        
        
        &:hover, &:focus, &.disabled:hover, &.disabled:focus {
            color: var(--mainColor);
            background-color: var(--whiteColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
        
        &.focus, &:focus {
            box-shadow: none;
        }
        
        &.disabled, &:disabled {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
            opacity: 1;
        }
    } 
    .btn-byd5 {
        color: black;
        background-color: #DF280D;
        border-color: #DF280D;
        position: static;
        margin-left: 0;
        top: 60%;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        
        
        &:hover, &:focus, &.disabled:hover, &.disabled:focus {
            color: var(--mainColor);
            background-color: var(--whiteColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
        
        &.focus, &:focus {
            box-shadow: none;
        }
        
        &.disabled, &:disabled {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
            opacity: 1;
        }
    } 
    .btn-raion {
        color: white;
        background-color: var(--mainColor);
        border-color: var(--mainColor);
        position: static;
        margin-left: 0;
        top: 60%;
        transform: translateY(-50%);
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 10px;;
        padding-right: 10px;;
        
        
        &:hover, &:focus, &.disabled:hover, &.disabled:focus {
            color: var(--mainColor);
            background-color: var(--whiteColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
        
        &.focus, &:focus {
            box-shadow: none;
        }
        
        &.disabled, &:disabled {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
            opacity: 1;
        }
    } 
    .boton {
        color: white;
        background-color:  #3A9B43;
        border-color:  #3A9B43;
        position: static;
        margin-left: 0;
        top: 60%;
        transform: translateY(-50%);
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 10px;;
        padding-right: 10px;;
        
        
        &:hover, &:focus, &.disabled:hover, &.disabled:focus {
            color: var(--mainColor);
            background-color: transparent;
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
            color: var(--whiteColor);
            background-color:  #3A9B43;
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
        
        &.focus, &:focus {
            box-shadow: none;
        }
        
        &.disabled, &:disabled {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
            opacity: 1;
        }
    } 
    .btn-three {
        color: var(--blackColor);
        background-color: var(--whiteColor);
        border-color: var(--whiteColor);
        position: static;
        margin-left: 0;
        top: 60%;
        transform: translateY(-50%);
        
        
        &:hover, &:focus, &.disabled:hover, &.disabled:focus {
            color: var(--mainColor);
            background-color: var(--whiteColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
        
        &.focus, &:focus {
            box-shadow: none;
        }
        
        &.disabled, &:disabled {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
            opacity: 1;
        }
    } 
    .btn-second {
        color: var(--blackColor);
        background-color: #EFE6C0;
        border-color: #EFE6C0;
        position: static;
        margin-left: 0;
        top: 60%;
        transform: translateY(-50%);
        
        
        &:hover, &:focus, &.disabled:hover, &.disabled:focus {
            color: var(--mainColor);
            background-color: #EFE6C0;
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
        
        &.focus, &:focus {
            box-shadow: none;
        }
        
        &.disabled, &:disabled {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
            opacity: 1;
        }
    } 
    .btn-vista {
        color: var(--blackColor);
        background-color: #EFE6C0;
        border-color: #EFE6C0;
        position: static;
        margin-left: 0;
        top: 60%;
        transform: translateY(-50%);
    } 

    /* Section Title */
    .section-title {
        margin-bottom: 60px;
        padding-left: 30px;
        position: relative;

        &::before, &::after {
            content: '';
            position: absolute;
            width: 3px;
            height: 100%;
            left: 10px;
            top: 0;
            background: var(--mainColor);
            transition: var(--transition);
        }
        &::after {
            left: 0;
            height: 50%;
        }
        &:hover::before {
            height: 50%;
        }
        &:hover::after {
            height: 100%;
        }
        h2 {
            margin-bottom: 20px;
            font: {
                size: 40px;
                weight: 400;
            }
            span {
                color: var(--mainColor);
                font-weight: 600;
            }
        }
        p {
            max-width: 635px;
            margin-top: -7px;
        }
        h4 {
            color: var(--mainColor);
            margin-bottom: 14px;
            text-transform: capitalize;
            font: {
                size: 17px;
                weight: 400;
            }
        }
    }
    /* Form Control */
    .form-control {
        height: 45px;
        padding: 10px;
        font-size: 15px;
        background-color: var(--whiteColor);
        border: 1px solid #eeeeee;
        border-radius: 0;

        &:focus {
            border-color: #dddddd;
            outline: 0;
            box-shadow: none;
        }
    }
    textarea.form-control {
        height: auto;
    }
    /* Owl Carousel Button */
    .owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 4px;
                    background: transparent;
                    border-radius: 50%;
                    border: 2px solid #dddddd;
                    transition: var(--transition);
                }
                &.active {
                    span {
                        background: #869791;
                        border-radius: 5px;
                        width: 35px;
                        height: 10px;
                        border-color: var(--mainColor);
                        background: var(--mainColor);
                    }
                }
                &:hover {
                    span {
                        background: var(--mainColor);
                        border-color: var(--mainColor);
                    }
                }
            }
        }
    }

    /*==============================
    Preloader CSS
    ==============================*/
    .preloader {
        position: fixed;
        width: 100%;
        height: 100%;
        background: var(--whiteColor);
        z-index: 99999;

        .folding-cube {
            left: 0;
            width: 60px;
            height: 60px;
            position: absolute;
            transform: rotateZ(45deg);
            top: 50%;
            right: 0;
            margin: -60px auto 0;

            .cube {
                float: left;
                width: 50%;
                height: 50%;
                position: relative;
                transform: scale(1.1);

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: var(--mainColor);
                    animation: sk-foldCubeAngle 2.4s infinite linear both;
                    transform-origin: 100% 100%;
                }
            }
            .cube2 {
                transform: scale(1.1) rotateZ(90deg);

                &::before {
                    animation-delay: 0.3s;
                }
            }
            .cube3 {
                transform: scale(1.1) rotateZ(180deg);

                &::before {
                    animation-delay: 0.6s;
                }
            }
            .cube4 {
                transform: scale(1.1) rotateZ(270deg);

                &::before {
                    animation-delay: 0.9s;
                }
            }
        }
    }
    @keyframes sk-foldCubeAngle {
        0%, 10% {
            transform: perspective(140px) rotateX(-180deg);
            opacity: 0; 
        } 
        25%, 75% {
            transform: perspective(140px) rotateX(0deg);
            opacity: 1; 
        } 
        90%, 100% {
            transform: perspective(140px) rotateY(180deg);
            opacity: 0; 
        }
    }

    /*==============================
    Main Header CSS
    ==============================*/
    .navbar-collapse {
        display: flex;
        justify-content: center; /* centra horizontalmente */
        align-items: center; /* centra verticalmente */
    }
    
    .navbar-right{
        display: flex;
        justify-content: center; /* centra horizontalmente */
        align-items: center; /* centra verticalmente */
    }
    
    .navbar {
        display: flex;
        justify-content: center;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        position: absolute !important;
        background: #ffffff !important;

        &.sticky-box-shadow {
            top: 0;
            left: 0;
            width: 100%;
            z-index: 999;
            position: fixed !important;
            background: #ffffff !important;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

        }
    }
    .navbar-light {
        .navbar-brand {
            color: var(--whiteColor);
            text-transform: uppercase;
            line-height: 10px;
            transition: var(--transition);
            font: {
                size: 23px;
                weight: 600;
            }
            span {
                color: var(--mainColor);
                line-height: 10px;
                text-transform: lowercase;
                transition: var(--transition);
                font: {
                    size: 40px;
                    weight: 700;
                }
            }
            &:focus, &:hover {
                color: var(--whiteColor);
            }
        }
    .navbar-nav {
    display: flex;
    justify-content: center; /* Centrar horizontalmente */
    align-items: center;
    .nav-item {
        text-align: right;
        padding: {

        top: 0;
        
        };
        &:last-child {
        padding-right: 0;
        }
        &:first-child {
        padding-left: 0;
        }
        .nav-link {
        color: var(--whiteColor);
        position: relative;
        cursor: pointer;
        font-weight: 500;
        transition: var(--transition);
        padding: {
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 0px;
            background-color: var(--mainColor);
            transition: var(--transition);
            transform: scaleX(0);
        }
        &.active,
        &:hover,
        &:focus {
            color: var(--mainColor);
            &::before {
            transform: scaleX(1);
            }
        }
        }
    }
    .navbar-nav .ml-auto {
        margin-left: auto;
    }
    .navbar-nav .text-right {
        margin-left: auto;
    }
    &.for-responsive {
        display: none;
    }
    }

        &.sticky-box-shadow {
            .navbar-brand {
                color: var(--blackColor);
            }
            .navbar-nav {
                .nav-link {
                    color: var(--blackColor);

                    &.active, &.show, &:hover, &:focus {
                        color: var(--mainColor);
                    }
                }
                .active, .show {
                    .nav-link {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
    // Mobile and iPad Navbar
    @media only screen and (max-width: 991px) {
        .navbar {
        padding-top: 15px;
        padding-bottom: 15px;
        &.navbar-light {
            .navbar-nav {
            background-color: var(--whiteColor);
            padding: 10px 20px;
            margin-top: 10px;
            display: none;
            &.for-responsive {
                display: block !important;
            }
            .nav-item {
                padding-left: 0;
                padding-right: 0;
                padding-top: 10px;
                padding-bottom: 10px;
                .nav-link {
                color: var(--blackColor);
                display: inline-block;
                &.active,
                &:hover {
                    color: var(--mainColor);
                }
                }
            }
            }
            .navbar-toggler {
            color: var(--whiteColor);
            font-size: inherit;
            box-shadow: unset;
            border: none;
            padding: 0;
            .burger-menu {
                cursor: pointer;
                span {
                height: 2px;
                width: 30px;
                margin: 6px 0;
                display: block;
                background: var(--whiteColor);
                }
            }
            }
            &.active {
            .navbar-toggler {
                .burger-menu {
                span {
                    &.top-bar {
                    transform: rotate(45deg);
                    transform-origin: 10% 10%;
                    }
                    &.middle-bar {
                    opacity: 0;
                    }
                    &.bottom-bar {
                    transform: rotate(-45deg);
                    transform-origin: 10% 90%;
                    margin-top: 5px;
                    }
                }
                }
            }
            .collapse:not(.show) {
                display: block;
            }
            }
            &.sticky-box-shadow {
            .navbar-nav {
                border: 1px solid #000000;
            }
            .navbar-toggler {
                color: var(--whiteColor);
                .burger-menu {
                span {
                    background: var(--whiteColor);
                }
                }
            }
            }
        }
        }
    }
    

    /*==============================
    Main Banner CSS
    ==============================*/
    .main-banner {
        height: 600px;
        position: relative;
        overflow: hidden;
        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
            color: #0B1F0D;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;


        }
        &.gradient-bg {        
            &::before {
                background: linear-gradient(90deg, #232021 0, #0093c8);
                opacity: .80;
            }
        }
    }
    .main-banner-about-img {
        height: 900px;
        position: relative;
        overflow: hidden;
        background: white;
        padding-top: 2em;        

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;


        }
        &.gradient-bg {        
            &::before {
                background: linear-gradient(90deg, #232021 0, #0093c8);
                opacity: .80;
            }
        }
    }
    .main-banner-products-img {
        height: 800px;
        position: relative;
        overflow: hidden;
        background: white;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;


        }
        &.gradient-bg {        
            &::before {
                background: linear-gradient(90deg, #232021 0, #0093c8);
                opacity: .80;
            }
        }
    }
    .main-banner-white {
        height: 800px;
        position: relative;
        overflow: hidden;
        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
            color: #ffffff;
        };
        
    }
    .main-banner-white-home-1 {
        position: relative;
        overflow: hidden;
        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
            color: #ffffff;
        };

    }
    .main-banner-white-about {
        height: 600px;
        position: relative;
        overflow: hidden;
        padding-left: 20%;
        padding-right: 20%;
        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
            color: #ffffff;
        };

    }
    .main-banner-white-products {
        height: 950px;
        position: relative;
        overflow: hidden;

        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
            color: #F8F8F8;
        };

    }
    .main-banner-white-service {
        height: 600px;
        position: relative;
        overflow: hidden;

        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
            color: #F8F8F8;
        };

    }
    .main-banner-red {
        height: 200px;
        position: relative;
        overflow: hidden;
        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
            color: #DF280D;
        };

    }
    .main-banners {
        height: 500px;
        position: relative;
        overflow: hidden;
        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: var(--whiteColor);
            opacity: 0;
        }
        &.gradient-bg {        
            &::before {
                background: linear-gradient(90deg, #232021 0, #0093c8);
                opacity: .80;
            }
        }
    }
    .main-banner-contact {
        height: 600px;
        position: relative;
        overflow: hidden;
        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
            color: #0B1F0D
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #0B1F0D;
            opacity: .58;
        }
        &.gradient-bg {        
            &::before {
                background: #0B1F0D;
                opacity: .80;
            }
        }
    }
    .main-banner-home {
        margin-top: 4em;
        height: 43rem;

        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
            image: url('/assets/eft/fotos/HOME/Home1.jpg');
        };      
        .btn-second {
            float: left;
        }

        &.gradient-bg {        
            &::before {
                background: #0B1F0D;
                opacity: .80;
            }
        }
    }
    .main-banner-ci{
        height: 600px;

        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
            color: #0B1F0D;
        };      
        .btn-second {
            float: left;
        }

        &.gradient-bg {        
            &::before {
                background: #0B1F0D;
                opacity: .80;
            }
        }
    }
    .main-banner-cea {
        height: 850px;
        position: relative;
        overflow: hidden;
        background: {
            color: #0B1F0D;
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #0B1F0D ;
            opacity: 0.58;
        }
        &.gradient-bg {        
            &::before {
                background: linear-gradient(90deg, #232021 0, #0093c8);
                opacity: .80;
            }
        }
    }
    .main-banner-byd {
        position: relative;
        overflow: hidden;
        background-color: #0B1F0D;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .main-banner-byd::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #0B1F0D;
        opacity: 0.58;
    }

    .main-banner-byd.gradient-bg::before {
        background: linear-gradient(90deg, #232021 0, #0093c8);
        opacity: 0.80;
    }


    .item-bg-two {
        background-image: url(assets/img/main-bg-2.jpg);
    }
    .item-bg-three {
        background-image: url(assets/img/main-bg-3.jpg);
    }
    .main-banner-text { 
        height: 19em;
        position: relative;
        text-align: center;
        z-index: 1;
        

        h4 {
            color: var(--whiteColor);
            font: {
                weight: 400;
                size: 20px;
            };
        }
        h2{
            color: var(--whiteColor);
            margin: {
                top: 30px;
                bottom: 30px;
            };
            font: {
                weight: 142;
                size: 35px;
            };
            span {
                color: #ff2c57;
                font-weight: 600;
            }
            ngx-typed-js {
                display: inline-block;

                div {
                    display: inline-block;

                    span {
                        display: inline-block;
                    }
                }
            }
        }
        h1 {
            color: var(--whiteColor);
            margin: {
                top: 30px;
                bottom: 0px;
            };
            font: {
                weight: 500;
                size: 75px;
            };
            span {
                color: #ff2c57;
                font-weight: 600;
            }
            ngx-typed-js {
                display: inline-block;

                div {
                    display: inline-block;

                    span {
                        display: inline-block;
                    }
                }
            }
        }
        p {
            color: var(--whiteColor);
            line-height: 0.3;
            max-width: 650px;
            font-size: 15.5px;
            margin: {
                left: auto;
                right: auto;
                bottom: 30px;
            };
        }
        .btn {
            margin: {
                left: 7px;
                right: 7px;
            };
            &:hover, &:focus {
                border-color: var(--whiteColor);
            }
        }
        .view-work {
            background: transparent;
            border-color: var(--whiteColor);

            &:hover, &:focus {
                border-color: var(--mainColor);
                background: var(--mainColor);
                color: var(--whiteColor);
            }
        }
        &.text-start {
            p {
                margin: {
                    left: 0;
                    right: 0;
                };
            }
        }
        &.text-end {
            p {
                margin: {
                    left: auto;
                    right: 0;
                };
            }
        }
    }
    .main-banner-texts { 
        position: relative;
        text-align: center;
        z-index: 1;
        

        h4 {
            color: var(--whiteColor);
            font: {
                weight: 400;
                size: 20px;
            };
        }
        h1 {
            text-align: center;
            color: var(--whiteColor);
            margin: {
                top: 30px;
                bottom: 30px;
            };
            font: {
                weight: 300;
                size: 35px;
            };
            span {
                color: #ff2c57;
                font-weight: 600;
            }
            ngx-typed-js {
                display: inline-block;

                div {
                    display: inline-block;

                    span {
                        display: inline-block;
                    }
                }
            }
        }
        p {
            color: #EFE6C0;
            max-width: 650px;
            font-size: 15.5px;
            text-align: left;

        }
        .btn {
            margin: {
                left: 7px;
                right: 7px;
            };
            &:hover, &:focus {
                border-color: var(--whiteColor);
            }
        }
        .view-work {
            background: transparent;
            border-color: var(--whiteColor);

            &:hover, &:focus {
                border-color: var(--mainColor);
                background: var(--mainColor);
                color: var(--whiteColor);
            }
        }
        &.text-start {
            p {
                margin: {
                    left: 0;
                    right: 0;
                };
            }
        }
        &.text-end {
            p {
                margin: {
                    left: auto;
                    right: 0;
                };
            }
        }
    }
    .home-slides {
        .owl-theme {
            .owl-nav {
                margin-top: 0;
                transition: var(--transition);
                opacity: 0;
                visibility: hidden;

                [class*="owl-"] {
                    position: absolute;
                    left: 40px;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba(255,255,255,0.33);
                    width: 55px;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    height: 55px;
                    line-height: 58px;
                    border-radius: 50%;
                    font-size: 25px;
                    color: var(--whiteColor);
                    transition: var(--transition);
        
                    &.owl-next {
                        left: auto;
                        right: 40px;
                    }
                    &:hover {
                        background: var(--mainColor);
                        color: var(--whiteColor);
                    }
                }
            }
            &:hover {
                .owl-nav {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .shape2 {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
    .shape3 {
        position: absolute;
        right: 50px;
        bottom: 0;
    }

    /*==============================
    Video Banner CSS
    ==============================*/
    .video-background {
        right: 0;
        bottom: 0;
        z-index: -100;
        position: absolute;
        min: {
            height: 100%;
            width: 100%;
        };
    }

    /*==============================
    Creative Banner CSS
    ==============================*/
    .creative-banner-one {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-width: 10rem 100vw 0 0;
        border-color: transparent var(--whiteColor) var(--whiteColor) transparent;
        border-style: solid;
    }
    .creative-banner-two {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        &::before {
            content: "";
            border-right: 50vw solid var(--whiteColor);
            border-top: 160px solid transparent;
            bottom: 0;
            right: 0;
            position: absolute;
            height: 0;
            width: 0;
        }
        &::after {
            content: '';
            position: absolute;
            border-left: 50vw solid var(--whiteColor);
            border-top: 160px solid transparent;
            bottom: 0;
            height: 0;
            left: 0;
            width: 0;
        }
    }
    .creative-banner-three {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;

        &::before {
            content: '';
            position: absolute;
            bottom: -25px;
            left: 0;
            width: 100%;
            height: 150px;
            background: {
                image: url(assets/img/shape.png);
                position: center center;
                size: cover;
                repeat: no-repeat;
            };
        }
    }

    /*==============================
    Welcome Area CSS
    ==============================*/
    .welcome-area {
        position: relative;
        z-index: 1;
    }
    .single-box {
        text-align: center;
        box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
        padding: 30px 20px;
        position: relative;
        overflow: hidden;
        transition: var(--transition);
        margin-bottom: 30px;

        .icon {
            color: var(--mainColor);
            font-size: 25px;
            width: 65px;
            border: 1px dashed var(--mainColor);
            height: 65px;
            position: relative;
            border-radius: 3px;
            transition: var(--transition);
            margin: {
                left: auto;
                right: auto;
            };
            i {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        h3 {
            transition: var(--transition);
            margin: {
                top: 25px;
                bottom: 12px;
            }
            font: {
                weight: 600;
                size: 20px;
            }
        }
        p {
            transition: var(--transition);
            font-size: 15.5px;
        }
        .link-btn {
            position: absolute;
            right: -20px;
            bottom: 0;
            width: 45px;
            height: 40px;
            line-height: 44px;
            text-align: center;
            box-shadow: 0 0 25px 0 rgba(0, 27, 103, 0.2);
            border-radius: 50% 0 0 0;
            opacity: 0;
            visibility: hidden;
            
        }
        &:hover {
            transform: translateY(-5px);
            background-color: var(--mainColor);
            border-bottom: 2px solid red; /* Reemplaza "red" con el color deseado */

            .icon {
                background-color: var(--whiteColor);
                border-color: var(--whiteColor);
            }
            h3 {
                color: var(--whiteColor);
            }
            p {
                color: var(--whiteColor);
            }

        }
    }

    /*==============================
    About Area CSS
    ==============================*/
    .about-area {
        position: relative;
        z-index: 1;
        background: {
            image: url(assets/img/bg1.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
    .about-content {
        .section-title {
            margin-bottom: 20px;
            padding-left: 0;

            &::before, &::after {
                display: none;
            }
            h2 {
                font-size: 23px;
            }
            p {
                font-size: 15.5px;
            }
        }
        .features-list {
            margin-bottom: 10px;
            display: flex;
            flex-wrap: wrap;

            li {
                margin-bottom: 10px;
                color: #727695;
                flex: 0 0 50%;
                max-width: 50%;
                font: {
                    size: 15.5px;
                };
                i {
                    color: var(--mainColor);
                    margin-right: 7px;
                }
            }
        }
    }
    .about-video {
        position: relative;

        .video-btn {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            text-align: center;
            transform: translateY(-50%);

            button {
                position: relative;
                display: inline-block;
                width: 90px;
                height: 90px;
                border: none;
                transition: var(--transition);
                line-height: 92px;
                background-color: var(--whiteColor);
                border-radius: 50%;
                color: var(--mainColor);
                z-index: 1;
                font-size: 35px;

                &::after, &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: -1;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    border: 1px solid var(--whiteColor);
                }
                &::before {
                    animation: ripple 2s linear infinite
                }
                &::after {
                    animation: ripple 2s linear 1s infinite
                }
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
    @keyframes pulse-border {
        0% {
            transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1);
            opacity:1;
        }
        100% {
            transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
            opacity:0;
        }
    }
    @keyframes ripple {
        0% {
            transform: scale(1)
        }
        75% {
            transform: scale(1.75);
            opacity: 1
        }
        100% {
            transform: scale(2);
            opacity: 0
        }
    }

    /*==============================
    Strategy Area CSS
    ==============================*/
    .strategy-area {
        position: relative;
        z-index: 1;

        .container-fluid {
            padding: {
                left: 0;
                right: 0;
            };
            .row {
                margin: {
                    left: 0;
                    right: 0;
                };
                .col-lg-6 {
                    padding: {
                        left: 0;
                        right: 0;
                    };
                }
            }
        }
    }
    .strategy-image {
        width: 100%;
        height: 100%;
        background: {
            image: url(assets/img/about-strategy.jpg);
            size: cover;
            position: center center;
            repeat: no-repeat;
        };
        img {
            display: none;
        }
    }
    .strategy-content {
        padding-left: 35px;
        
        .section-title {
            margin-bottom: 20px;
            padding-left: 0;

            &::before, &::after {
                display: none;
            }
            p {
                font-size: 15.5px;
            }
        }
        .features-list {
            margin-bottom: 20px;

            li {
                margin-bottom: 10px;
                color: #727695;
                font: {
                    size: 15.5px;
                };
                i {
                    color: var(--mainColor);
                    margin-right: 7px;
                }
            }
        }
        p {
            max-width: 635px;
        }
    }
    .shape1 {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    /*==============================
    Who We Are Area CSS
    ==============================*/
    .who-we-are-area {
        position: relative;
        z-index: 1;
        background: {
            image: url(assets/img/bg2.jpg);
            position: center center;
        };
    }
    .single-who-we-are {
        margin-bottom: 30px;
        position: relative;
        z-index: 1;
        background: var(--whiteColor);
        border: 1.5px dashed #eeeeee;
        padding: 30px;
        transition: var(--transition);

        i {
            font-size: 50px;
            color: var(--mainColor);
            transition: var(--transition);
        }
        h3 {
            transition: var(--transition);
            text-transform: capitalize;
            margin: {
                bottom: 15px;
                top: 23px;
            };
            font: {
                size: 20px;
                weight: 600;
            };
        }
        p {
            font-size: 15.5px;
            transition: var(--transition);
        }
        span {
            bottom: 0;
            color: var(--blackColor);
            opacity: .02;
            font-size: 200px;
            line-height: 180px;
            position: absolute;
            right: 0;
            transition: var(--transition);
        }
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 0;
            width: 100%;
            transition: var(--transition);
            background: var(--mainColor);
            z-index: -1;
        }
        &:hover {
            box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
            color: var(--whiteColor);
            transform: translateY(-10px);

            i {
                color: var(--whiteColor);
            }
            p {
                color: var(--whiteColor);
                opacity: 0.9;
            }
            h3 {
                color: var(--whiteColor);
            }
            span {
                color: var(--whiteColor);
                opacity: 0.2;
            }
            &::before {
                height: 100%;
            }
        }
    }

    /*==============================
    CTA Area CSS
    ==============================*/
    .cta-area {
        position: relative;
        z-index: 1;
        background: {
            image: url(assets/img/ctr-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: var(--blackColor);
            opacity: .60;
        }
    }
    .cta-content {
        text-align: center;

        .section-title {
            padding-left: 0;

            &::after, &::before {
                display: none;
            }
            h2 {
                color: var(--whiteColor);
                font-weight: 600;
                margin: {
                    top: 25px;
                    bottom: 25px;
                };
            }
            p {
                color: var(--whiteColor);
                opacity: .95;
                margin: {
                    left: auto;
                    right: auto;
                    bottom: 30px;
                };
            }
            .btn {
                margin: {
                    left: 8px;
                    right: 8px;
                };
                &.btn-primary {
                    &:hover, &:focus {
                        border-color: var(--whiteColor);
                    }
                }
            }
            .view-work {
                background: transparent;
                border-color: var(--whiteColor);
        
                &:hover, &:focus {
                    border-color: var(--mainColor) !important;
                    background: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }

    /*==============================
    Skill Area CSS
    ==============================*/
    .skill-content {
        .section-title {
            margin-bottom: 40px;
        }
        .skills-list {
            .skill-item {
                margin-bottom: 25px;
            
                .skill-header {
                    position: relative;
                    margin-bottom: 10px;
        
                    .skill-title {
                        margin-bottom: 0;
                        font: {
                            size: 16px;
                            weight: 600;
                        };
                    }
                    .skill-percentage {
                        position: absolute;
                        right: 0;
                        top: -2px;
                        color: var(--blackColor);
                        font: {
                            size: 15.5px;
                            weight: 500;
                        };
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .skill-bar {
                    position: relative;
                    width: 100%;
        
                    .bar-inner {
                        position: relative;
                        width: 100%;
                        border-bottom: 7px solid var(--blackColor);
                        border-radius: 30px;
        
                        .bar {
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            height: 7px;
                            background: var(--mainColor);
                            transition: all 2000ms ease;
                            border-radius: 30px;
                        }
                    }
                }
            }
        }
    }
    .skill-video {
        position: relative;

        .video-btn {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            text-align: center;
            transform: translateY(-50%);

            button {
                position: relative;
                display: inline-block;
                width: 90px;
                border: none;
                height: 90px;
                line-height: 92px;
                background-color: var(--whiteColor);
                border-radius: 50%;
                transition: var(--transition);
                color: var(--mainColor);
                z-index: 1;
                font-size: 35px;

                &::after, &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: -1;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    border: 1px solid var(--whiteColor);
                }
                &::before {
                    animation: ripple 2s linear infinite
                }
                &::after {
                    animation: ripple 2s linear 1s infinite
                }
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
    @keyframes animate-positive {
        0% { 
            width: 0; 
        }
    }

    /*==============================
    Fun Facts Area CSS
    ==============================*/
    .funfacts-area {
        position: relative;
        z-index: 1;
        background: {
            image: url(assets/img/funfact-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: var(--blackColor);
            opacity: .60;
        }
    }
    .funfact {
        position: relative;
        padding-left: 65px;
        margin-bottom: 30px;
        
        i {
            font-size: 50px;
            color: var(--mainColor);
            position: absolute;
            left: 0;
            top: 2px;
        }
        h3 {
            color: var(--whiteColor);
            margin-bottom: 5px;
            font: {
                size: 45px;
                weight: 600;
            };
        }
        p {
            color: var(--whiteColor);
            font-size: 16px;
        }
    }

    /*==============================
    Team Area CSS
    ==============================*/
    .team-area {
        position: relative;
        z-index: 1;
    }
    .single-team-box {
        margin-bottom: 60px;
        text-align: center;
        position: relative;

        .image {
            img {
                width: 100%;
            }
        }
        .content {
            position: absolute;
            left: 0;
            bottom: -30px;
            right: 0;
            background: var(--whiteColor);
            margin-left: 15px;
            z-index: 1;
            transition: var(--transition);
            box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
            padding: 20px;
            margin: {
                left: 15px;
                right: 15px;
            };
            .title {
                margin-bottom: 10px;
                transition: var(--transition);
                font: {
                    size: 20px;
                    weight: 600;
                };
            }
            .post {
                color: var(--mainColor);
                transition: var(--transition);
                font-size: 15px;
            }
        }
        .social {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to top, rgba(0,0,0,0.9) 20%, rgba(0,0,0,0.3) 80%);
            transition: var(--transition);
            opacity: 0;
            visibility: hidden;

            ul {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                margin-top: -50px;
                transform: translateY(-35px);
                transition: var(--transition);

                li {
                    display: inline-block;
                    margin: {
                        left: 3px;
                        right: 3px;
                    };
                    a {
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        color: var(--whiteColor);
                        display: block;
                        border: 1px solid var(--whiteColor);
                        border-radius: 50%;

                        &:hover {
                            border-color: var(--mainColor);
                            background: var(--mainColor);
                        }
                    }
                }
            }
        }
        &:hover {
            .content {
                bottom: 25%;
                background: transparent;
                box-shadow: unset;

                .title {
                    color: var(--whiteColor);
                }
                .post {
                    color: var(--whiteColor);
                }
            }
            .social {
                opacity: 1;
                visibility: visible;

                ul {
                    transform: translateY(0);
                }
            }
        }
    }
    .team-slides {
        .owl-theme {
            .owl-nav {
                &.disabled+.owl-dots {
                    margin-top: 20px;
                }
            }
        }
    }

    /*==============================
    How We Work Area CSS
    ==============================*/
    .how-work-area {
        position: relative;
        z-index: 1;
        background: {
            image: url(assets/img/dott.jpg);
            position: center;
            size: contain;
            repeat: repeat;
        };
    }
    .single-how-work {
        transition: var(--transition);
        margin-bottom: 30px;
        position: relative;

        .icon {
            width: 75px;
            height: 75px;
            position: relative;
            text-align: center;
            font-size: 30px;
            color: var(--mainColor);
            transition: var(--transition);
            margin-bottom: 28px;
            border: {
                width: 2px;
                style: dashed;
                color: var(--mainColor);
                radius: 50%;
            };
            i {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &::before {
            content: '';
            position: absolute;
            right: 75px;
            top: 10px;
            width: 110px;
            height: 75px;
            transform: rotate(-15deg);
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
                image: url(assets/img/black-arrow.png);
            };
        }
        h3 {
            margin-bottom: 10px;
            font: {
                size: 21px;
                weight: 600;
            };
        }
        p {
            font-size: 15.5px;
        }
        &:hover {
            transform: translateY(-5px);

            .icon {
                background-color: var(--mainColor);
                color: var(--whiteColor);
                border-color: var(--mainColor);
            }
        }
    }
    .col-lg-4 {
        &:nth-child(3) {
            .single-how-work {
                &::before {
                    display: none;
                }
            }
        }
    }

    /*==============================
    Services Area CSS
    ==============================*/
    .services-area {
        position: relative;
        z-index: 1;
    }
    .single-services {
        text-align: left;

        position: relative;
        li{
            text-align: left;

        }
        
        .services-img {
        position: relative;
        text-align: center;
        

            .icon {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);

                i {
                    width: 70px;
                    height: 70px;
                    line-height: 71px;
                    border: 1px dashed var(--mainColor);
                    font-size: 30px;
                    border-radius: 50%;
                    transition: var(--transition);
                    background: var(--whiteColor);
                    color: var(--mainColor);
                }
            }
        }
        .services-content-home {
            padding: 5px 5px;
            position: relative;

            h3 {
                margin-bottom: 0px;
                font: {
                    size: 20px;
                    weight: 600;
                };
            }
            p {
                font-size: 15.5px;
            }
        }
        .services-content {
            padding: 20px 25px;
            
            position: relative;
            height: auto;

            h3 {
                margin-bottom: 0px;
                font: {
                    size: 20px;
                    weight: 600;
                };
            }
            p {
                font-size: 15.5px;
                
            }
        }
        &:hover {
            transform: translateY();

            .services-img {
                .icon {
                    i {
                        background: var(--whiteColor);
                        border-color: var(--whiteColor);
                        color: var(--whiteColor);
                    }
                }
            }
            &::before {
                opacity: 1;
                width: 100%;
                height: 50%;
            }
        }
        margin-bottom: 10px;
    }
    .services-content-hom {
        padding: 20px 25px;
        position: relative;

        h3 {
            margin-bottom: 0px;
            font: {
                size: 20px;
                weight: 600;
            };
        }
        p {
            font-size: 15.5px;
        }
    }
    .single-services-bat {
        margin-bottom: 30px;
        text-align: center;
        position: relative;
        li{
            text-align: left;
        }
        
        .services-img {
        position: relative;
        

            .icon {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);

                i {
                    width: 70px;
                    height: 70px;
                    line-height: 71px;
                    border: 1px dashed var(--mainColor);
                    font-size: 30px;
                    border-radius: 50%;
                    transition: var(--transition);
                    background: var(--whiteColor);
                    color: var(--mainColor);
                }
            }
        }
        .services-content {

            position: relative;


            h3 {
                margin-bottom: 13px;
                font: {
                    size: 20px;
                    weight: 600;
                };
            }
            p {
                font-size: 15.5px;
            }
        }

    }

    /*==============================
    Why We Are Different CSS
    ==============================*/
    .why-we-different-tabset {
        &.tabset {
            input[type="text"] {
                display: none;
            }
            .tab-panel {
                display: none;
            }
            input {
                &:first-child:checked ~ .tab-panels {
                    .tab-panel {
                        &:first-child {
                            display: block;
                        }
                    }
                }
                &:nth-child(3):checked ~ .tab-panels {
                    .tab-panel {
                        &:nth-child(2) {
                            display: block;
                        }
                    }
                }
                &:nth-child(5):checked ~ .tab-panels {
                    .tab-panel {
                        &:nth-child(3) {
                            display: block;
                        }
                    }
                }
                &:nth-child(7):checked ~ .tab-panels {
                    .tab-panel {
                        &:nth-child(4) {
                            display: block;
                        }
                    }
                }
                &:nth-child(9):checked ~ .tab-panels {
                    .tab-panel {
                        &:nth-child(5) {
                            display: block;
                        }
                    }
                }
                &:nth-child(11):checked ~ .tab-panels {
                    .tab-panel {
                        &:nth-child(6) {
                            display: block;
                        }
                    }
                }
            }
            label {
                position: relative;
                display: inline-block;
                padding: 15px 32px;
                cursor: pointer;
                background: transparent;
                transition: var(--transition);
                border: 1px solid #dddddd;
                border-radius: 5px;
                font: {
                    size: 18px;
                    weight: 500;
                };
                margin: {
                    right: 10px;
                    bottom: 0;
                };
            }
            input:checked + label {
                border-color: var(--mainColor);
                background: var(--mainColor);
                color: var(--whiteColor);
            }
        }
        .tab-panel {
            margin-top: 35px;
        }
    }
    .why-we-different-content {
        padding-left: 10px;
        
        h3 {
            margin-bottom: 15px;
            font: {
                size: 25px;
                weight: 500;
            };
        }
        ul {
            margin: {
                top: 20px;
                bottom: 0;
            };
            li {
                margin-bottom: 10px;
                font-weight: 300;
                color: #727695;

                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    color: var(--mainColor);
                    margin-right: 5px;
                }
            }
        }
    }
    .why-we-different-img {
        text-align: center;
    }

    /*==============================
    Work Area CSS
    ==============================*/
    .work-area {
        position: relative;
        z-index: 1;
    }
    .shorting-menu {
        .filter {
            border: none;
            background: #f4f4f4;
            padding: 9px 30px;
            text-transform: capitalize;
            cursor: pointer;
            transition: var(--transition);
            border-radius: 30px;
            color: var(--blackColor);
            margin-right: 10px;
            font: {
                size: 16px;
                weight: 500;
            };
            &.active, &:hover {
                background: var(--mainColor);
                color: var(--whiteColor);
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .work-tabs {
        .nav-tabset {
            padding: 20px;
            max-width: 1320px;
            border-radius: 50px;
            list-style-type: none;
            box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
            margin: {
                left: auto;
                right: auto;
                bottom: 40px;
            };
            .nav-tab {
                display: inline-block;
                margin-right: 10px;

                span {
                    display: block;
                    border: none;
                    background: #f4f4f4;
                    padding: 9px 30px;
                    text-transform: capitalize;
                    cursor: pointer;
                    transition: var(--transition);
                    border-radius: 30px;
                    color: var(--blackColor);
                    font: {
                        size: 16px;
                        weight: 500;
                    };
                    &:hover {
                        background: var(--mainColor);
                        color: var(--whiteColor);
                    }
                }
                &.active {
                    span {
                        background: var(--mainColor);
                        color: var(--whiteColor);
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .single-work {
        margin-bottom: 30px;
        position: relative;
        text-align: center;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            background: var(--blackColor);
            left: 0;
            opacity: 0;
            right: 0;
            bottom: 0;
            visibility: hidden;
            transition: var(--transition);
        }
        .work-content {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: var(--whiteColor);
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);
            margin: {
                left: 20px;
                right: 20px;
            };
            padding: {
                top: 20px;
                bottom: 20px;
                left: 10px;
                right: 10px;
            };
            h3 {
                margin-bottom: 10px;
                font: {
                    weight: 600;
                    size: 20px;
                };
            }
            ul {
                li {
                    display: inline-block;
                    font-size: 15px;
                    margin: {
                        left: 1px;
                        right: 1px;
                    };
                }
            }
        }
        .popup-btn {
            position: absolute;
            top: 20px;
            right: 40px;
            width: 45px;
            text-align: center;
            height: 45px;
            line-height: 45px;
            border-radius: 50%;
            color: var(--whiteColor);
            font-size: 22px;
            opacity: 0;
            visibility: hidden;
            border: 1px solid var(--whiteColor);
            transition: var(--transition);

            &:hover {
                background: var(--mainColor);
                border-color: var(--mainColor);
            }
        }
        &:hover {
            &::before {
                margin: 10px;
                opacity: .50;
                visibility: visible;
            }
            .popup-btn {
                top: 35px;
                opacity: 1;
                visibility: visible;
            }
            .work-content {
                bottom: 20px;
                opacity: 1;
                visibility: visible;
            }
        }
    }

    /*==============================
    Pricing Area CSS
    ==============================*/
    .pricing-area {
        position: relative;
        z-index: 1;
    }
    .pricingTable {
        margin-bottom: 30px;
        background-color: var(--whiteColor);
        box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
        text-align: center;
        transition: var(--transition);
        position: relative;
        background: {
            image: url(assets/img/patt.png);
            position: center center;
        };
        padding: {
            bottom: 30px;
        };
        &::after {
            content: "";
            width: 70px;
            height: 30px;
            background: var(--mainColor);
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            clip-path: polygon(50% 100%, 0 0, 100% 0);
            margin: {
                left: auto;
                right: auto;
            };
        }
        .title {
            margin-bottom: 35px;
            background: var(--mainColor);
            color: var(--whiteColor);
            text-transform: uppercase;
            padding: {
                top: 20px;
                bottom: 20px;
            };
            font: {
                weight: 500;
                size: 24px;
            };
        }
        .price-value {
            margin-bottom: 25px;
            color: var(--blackColor);

            .amount {
                display: inline-block;
                font: {
                    size: 44px;
                    weight: 600;
                };
            }
            .month {
                display: block;
                line-height: 16px;
                text-transform: capitalize;
                color: #727695;
                margin-left: 50px;
                font: {
                    size: 15px;
                    weight: 400;
                };
            }
        }
        .currency {
            display: inline-block;
            font-size: 25px;
            vertical-align: top;
            margin-top: 6px;
        }
        .pricing-content {
            margin-bottom: 30px;
            border: {
                top: 1px solid #eeeeee;
                bottom: 1px solid #eeeeee;
            };
            padding: {
                top: 25px;
                bottom: 25px;
            };
            li {
                color: #727695;
                margin-top: 16px;
                
                &:first-child {
                    margin-top: 0;
                }
                i {
                    color: var(--mainColor);
                    margin-right: 4px;
                }
            }
        }
        &:hover {
            transform: translateY(-10px);
        }
    }
    .col-lg-4 {
        &:nth-child(2) {
            .pricingTable {
                &::after {
                    background: #424242;
                }
                .title {
                    background: #424242;
                    color: var(--whiteColor);
                }
                .pricing-content {
                    li {
                        i {
                            color: #424242;
                        }
                    }
                }
                .btn-primary {
                    color: var(--whiteColor);
                    background-color: #424242;
                    border-color: #424242;

                    &:hover {
                        background-color: transparent;
                        color: #424242;
                        border-color: #424242;
                    }
                }
            }
        }
    }

    /*==============================
    FAQ Area CSS
    ==============================*/
    .faq-image {
        width: 100%;
        height: 100%;
        background: {
            image: url(assets/img/faq-img.jpg);
            size: cover;
            position: center center;
            repeat: no-repeat;
        };
        img {
            display: none;
        }
    }
    .card-cea {
        border-radius: none !important;
        display: block;
        margin-bottom: 15px;
        border: none;
        background-color: var(--whiteColor);
        box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;

        .card-header {
            padding: 5px;
            margin-bottom: 0;
            border: none;
            background: transparent;

            &:first-child {
                border-radius: 0;
            }
            a {
                display: block;

                &:hover {
                    background: var(--mainColor);
                }
                h5 {
                    overflow: hidden;
                    padding: 20px 40px 20px 20px;
                    color: var(--whiteColor);
                    background: var(--mainColor);
                    position: relative;
                    font: {
                        size: 16px;
                        weight: 500;
                    };
                }
                i {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    font-size: 16px;
                    transform: translateY(-50%) rotate(180deg);
                }
                &.collapsed {
                    h5 {
                        color: var(--blackColor);
                        background: transparent;
                        transition: var(--transition);
        
                        &:hover {
                            color: var(--whiteColor);
                        }
                    }
                    i {
                        transform: translateY(-50%) rotate(0);
                    }
                }
            }
        }
        .card-body {
            padding: 6em;
            line-height: 1.9;
            background: var(--whiteColor);
            color: #727695;
        }
    }
    .card-byd {
        border-radius: none !important;
        display: block;
        margin-bottom: 15px;
        border: none;
        background-color: #EFE6C0;
        box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;

        .card-header {
            padding: 5px;
            margin-bottom: 0;
            border: none;
            background: transparent;

            &:first-child {
                border-radius: 0;
            }
            a {
                display: block;

                &:hover {
                    background: var(--mainColor);
                }
                h5 {
                    overflow: hidden;
                    padding: 20px 40px 20px 20px;
                    color: var(--whiteColor);
                    background: var(--mainColor);
                    position: relative;
                    font: {
                        size: 16px;
                        weight: 500;
                    };
                }
                i {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    font-size: 16px;
                    transform: translateY(-50%) rotate(180deg);
                }
                &.collapsed {
                    h5 {
                        color: var(--blackColor);
                        background: transparent;
                        transition: var(--transition);
        
                        &:hover {
                            color: var(--whiteColor);
                        }
                    }
                    i {
                        transform: translateY(-50%) rotate(0);
                    }
                }
            }
        }
        .card-body {
            padding: 6em;
            line-height: 1.9;
            background: #EFE6C0;
            color: #727695;
        }
    }
    .card-contacts {
        border-radius: none !important;
        display: block;
        margin-bottom: 15px;
        border: none;
        background-color: #ffffff;
        box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;

        .card-header {
            padding: 5px;
            margin-bottom: 0;
            border: none;
            background: transparent;

            &:first-child {
                border-radius: 0;
            }
            a {
                display: block;

                &:hover {
                    background: var(--mainColor);
                }
                h5 {
                    overflow: hidden;
                    padding: 20px 40px 20px 20px;
                    color: var(--whiteColor);
                    background: var(--mainColor);
                    position: relative;
                    font: {
                        size: 16px;
                        weight: 500;
                    };
                }
                i {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    font-size: 16px;
                    transform: translateY(-50%) rotate(180deg);
                }
                &.collapsed {
                    h5 {
                        color: var(--blackColor);
                        background: transparent;
                        transition: var(--transition);
        
                        &:hover {
                            color: var(--whiteColor);
                        }
                    }
                    i {
                        transform: translateY(-50%) rotate(0);
                    }
                }
            }
        }
        .card-body {
            padding: 6em;
            line-height: 1.9;
            background: #EFE6C0;
            color: #727695;
        }
    }
    .faq-accordion {
        .card {
            border-radius: none !important;
            display: block;
            margin-bottom: 15px;
            border: none;
            background-color: var(--whiteColor);
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
        
            .card-header {
                padding: 0;
                margin-bottom: 0;
                border: none;
                background: transparent;
        
                &:first-child {
                    border-radius: 0;
                }
                a {
                    display: block;
        
                    &:hover {
                        background: var(--mainColor);
                    }
                    h5 {
                        overflow: hidden;
                        padding: 20px 40px 20px 20px;
                        color: var(--whiteColor);
                        background: var(--mainColor);
                        position: relative;
                        font: {
                            size: 16px;
                            weight: 500;
                        };
                    }
                    i {
                        position: absolute;
                        right: 20px;
                        top: 50%;
                        font-size: 16px;
                        transform: translateY(-50%) rotate(180deg);
                    }
                    &.collapsed {
                        h5 {
                            color: var(--blackColor);
                            background: transparent;
                            transition: var(--transition);
            
                            &:hover {
                                color: var(--whiteColor);
                            }
                        }
                        i {
                            transform: translateY(-50%) rotate(0);
                        }
                    }
                }
            }
            .card-body {
                line-height: 1.9;
                background: transparent;
                color: #727695;
            }
        }
        .panel {
            border: none;
            display: block;
            border-radius: px;
            margin-bottom: 15px;
            background-color: var(--whiteColor);
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13);
        }
        .panel-heading {
            .panel-title {
                margin: 0;

                a {
                    display: block;
                    position: relative;
                    color: var(--blackColor);
                    padding: 20px 40px 20px 20px;
                    background: var(--whiteColor);
                    font: {
                        size: 16px;
                        weight: 500;
                    };
                    &:hover {
                        color: var(--whiteColor);
                        background: var(--mainColor);
                    }
                    &::before {
                        top: 50%;
                        right: 20px;
                        font-size: 16px;
                        content: "\f078";
                        position: absolute;
                        transform: translateY(-50%);
                        font: {
                            family: Font Awesome\ 5 Free;
                            weight: 900;
                        };
                    }
                }
            }
        }
        .dropup {
            .panel-heading {
                .panel-title {    
                    a {
                        color: var(--whiteColor);
                        background: var(--mainColor);

                        &::before {
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }
                }
            }
        }
        accordion-group {
            &:last-child {
                .panel {
                    margin-bottom: 0;
                }
            }
        }
        .panel-body {
            padding: 20px;
        }
        .collapse {
            &.in {
                display: block;
            }
        }
    }

    /*==============================
    Feedback Area CSS
    ==============================*/
    .feedback-area {
        position: relative;
        z-index: 1;
        background-color: #F7F5F4;
    }
    .single-feedback-item {
        text-align: left;
        max-width: 720px;
        margin: {
            left: auto;
            right: auto;
        };
        p {
            color: var(--blackColor);
            margin-bottom: 0;
            font: {
                weight: 600;
                size: 22px;
            };
        }
        .info {
            text-align: right;
            margin-top: 50px;
            position: relative;
            z-index: 1;
            padding: {
                right: 118px;
                bottom: 22px;
            };
            img {
                width: 100px !important;
                height: 100px;
                display: inline-block !important;
                border: 3px solid var(--whiteColor);
                position: absolute;
                right: 0;
                bottom: 0;
            }
            h3 {
                margin: {
                    bottom: 6px;
                    top: 20px;
                };
                font: {
                    size: 20px;
                    weight: 500;
                };
            }
            span {
                color: var(--mainColor);
                display: block;
                font: {
                    weight: 500;
                    size: 15px;
                };
            }
            &::before {
                content: '';
                position: absolute;
                right: 290px;
                bottom: 45px;
                width: 110px;
                height: 75px;
                background: {
                    position: center center;
                    size: cover;
                    repeat: no-repeat;
                    image: url(assets/img/arrow.png);
                };
            }
        }
    }
    .feedback-slides {
        .owl-theme {
            margin-bottom: -40px;

            .single-feedback-item {
                margin-bottom: 40px;
            }
            .owl-nav {
                margin-top: 0;

                [class*=owl-] {
                    color: #87a1c1;
                    font-size: 25px;
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    width: 48px;
                    height: 48px;
                    line-height: 48px;
                    position: absolute;
                    left: 50px;
                    transition: var(--transition);
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: 0;
                    visibility: hidden;
                    border: {
                        radius: 50%;
                        style: solid;
                        color: #e3e4ef;
                        width: 2px;
                    };
                    &.owl-next {
                        left: auto;
                        right: 50px;
                    }
                    &:hover, &:focus {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                        border-color: var(--mainColor);
                        box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
                    }
                    &.disabled {
                        color: #e3e4ef;

                        &:hover, &:focus {
                            background-color: transparent;
                            color: #e3e4ef;
                            border-color: #e3e4ef;
                            box-shadow: unset;
                        }
                    }
                }
            }
            &:hover, &:focus {
                .owl-nav {    
                    [class*=owl-] {
                        left: 70px;
                        opacity: 1;
                        visibility: visible;

                        &.owl-next {
                            left: auto;
                            right: 70px;
                        }
                    }
                }
            }
        }
    }

    /*==============================
    Blog Area CSS
    ==============================*/
    .blog-area {
        position: relative;
        z-index: 1;
    }
    .single-blog-item {
        position: relative;
        padding: 30px;
        border: 1px solid #eeeeee;
        margin-bottom: 30px;
        z-index: 1;
        overflow: hidden;
        background: {
            size: cover;
            position: center center;
            repeat: no-repeat;
        };
        &::before {
            z-index: -1;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: var(--whiteColor);
            transition: var(--transition);
        }
        span {
            display: block;
            color: var(--mainColor);
        }
        span, p, h3 {
            position: relative;
            transition: var(--transition);
        }
        p {
            font-size: 15.5px;
        }
        h3 {
            line-height: 1.5;
            margin: {
                top: 12px;
                bottom: 12px;
            };
            font: {
                size: 20px;
                weight: 500;
            };
            a {
                display: inline-block;

                &:hover {
                    color: var(--mainColor) !important;
                }
            }
        }
        .link-btn {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 45px;
            height: 40px;
            line-height: 44px;
            text-align: center;
            box-shadow: 0px 0px 25px 0px rgba(0, 27, 103, 0.3);
            border-radius: 50% 0 0 0;
        }
        &.bg1 {
            background-image: url(assets/img/blog-img1.jpg);
        }
        &.bg2 {
            background-image: url(assets/img/blog-img2.jpg);
        }
        &.bg3 {
            background-image: url(assets/img/blog-img3.jpg);
        }
        &.bg4 {
            background-image: url(assets/img/blog-img4.jpg);
        }
        &.bg5 {
            background-image: url(assets/img/blog-img5.jpg);
        }
        &.bg6 {
            background-image: url(assets/img/blog-img6.jpg);
        }
        &.bg7 {
            background-image: url(assets/img/blog-img7.jpg);
        }
        &.bg8 {
            background-image: url(assets/img/blog-img8.jpg);
        }
        &.bg9 {
            background-image: url(assets/img/blog-img9.jpg);
        }
        &:hover {
            &::before {
                background: var(--blackColor);
                opacity: .66;
            }
            span, p, h3 a {
                color: var(--blackColor);
            }
            .link-btn {
                background: var(--mainColor);
                color: var(--whiteColor);
            }
        }
    }

    /*==============================
    Partner Area CSS
    ==============================*/
    .partner-area {
        position: relative;
        z-index: 1;
        background: {
            image: url(assets/img/bg1.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
    .partner-item {
        margin-bottom: 30px;
        text-align: center;

        a {
            display: block;

            img {
                width: auto !important;
                display: inline-block !important;
            }
        }
    }

    /*==============================
    Subscribe Area CSS
    ==============================*/
    .subscribe-area {
        background: {
            image: url(assets/img/pattern.png);
            position: center;
            size: contain;
            repeat: repeat;
            attachment: fixed;
        };
    }
    .newsletter {
        max-width: 750px;
        box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
        background: var(--whiteColor);
        padding: 40px 35px;
        text-align: center;
        border-radius: 5px;
        margin: {
            left: auto;
            right: auto;
        };
        h2 {
            text-transform: capitalize;
            margin-bottom: 30px;
            font: {
                size: 35px;
                weight: 500;
            };
        }
        form {
            position: relative;

            .form-control {
                box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
                border: none;
                height: 75px;
                border-radius: 50px;
                padding-left: 25px;
                font-size: 16px;
            }
            .btn {
                position: absolute;
                right: 7px;
                top: 8px;
                height: 60px;
                width: 175px;
                font-size: 17px;
            }
        }
    }

    /*==============================
    Contact Area CSS
    ==============================*/
    #map {
        width: 100%;
        height: 370px;
    }
    .contact-info {
        box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
        background: var(--whiteColor);
        padding: 30px;
        height: 100%;
        border: {
            top: 3px solid var(--mainColor);
            bottom: 3px solid var(--mainColor);
        };
        ul {
            li {
                position: relative;
                padding-left: 60px;
                font-size: 16px;
                margin-bottom: 34px;
                color: var(--blackColor);

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    display: inline-block;
                }
                span {
                    display: block;
                    color: var(--blackColor);
                    margin-bottom: 5px;
                    font: {
                        size: 20px;
                        weight: 500;
                    };
                }
                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    color: var(--mainColor);
                    font-size: 19px;
                    background: #f6f6f6;
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    border-radius: 50%;
                    transition: var(--transition);
                    transform: translateY(-50%);
                }
                &:hover {
                    i {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }
    .contact-form {
        background: var(--whiteColor);
        box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
        padding: 30px;
        border: {
            top: 3px solid var(--mainColor);
            bottom: 3px solid var(--mainColor);
        };
        h3 {
            color: var(--mainColor);
            margin-bottom: 25px;
            font: {
                size: 20px;
                weight: 600;
            };
        }
        .form-control {

            &:focus {
                border-color: var(--mainColor);
            }
        }
        label {
            margin-bottom: .5rem;
        }
        .btn {
            margin-top: 15px;
        }
        #message {
            height: auto;
            padding: 15px;
        }
    }

    /*==============================
    Page Title CSS
    ==============================*/
    .page-title-area {
        height: 500px;
        position: relative;
        overflow: hidden;
        text-align: center;
        background: {
            position: center;
            size: cover;
            attachment: fixed;
            repeat: no-repeat;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: var(--blackColor);
            opacity: .6;
        }
    }
    .page-title-content {
        position: relative;
        z-index: 1;
        margin-top: 70px;

        h1 {
            color: var(--whiteColor);
            margin: {
                bottom: 35px;
            };
            font: {
                size: 45px;
                weight: 600;
            };
        }
        ul {
            li {
                display: inline-block;
                color: var(--whiteColor);
                text-transform: uppercase;
                font-weight: 500;
                margin: {
                    left: 5px;
                    right: 5px;
                };
                &.active {
                    color: #cccccc;
                }
                a {
                    color: var(--whiteColor);
                    
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }

    /*==============================
    Blog Details Area CSS
    ==============================*/
    .blog-details-area {
        background: {
            image: url(assets/img/pattern.png);
            attachment: fixed;
        };
        .title {
            margin: {
                top: 30px;
                bottom: 15px;
            };
            font: {
                weight: 500;
                size: 25px;
            };
        }
    }
    .blog-details {
        box-shadow: 0 5px 10px rgba(0,9,128,0.035),0 7px 18px rgba(0,9,128,0.05);
        background: var(--whiteColor);

        .article-img {
            position: relative;

            .date {
                position: absolute;
                bottom: 15px;
                width: 100px;
                left: 15px;
                height: 100px;
                text-align: center;
                background: var(--mainColor);
                color: var(--whiteColor);
                border-radius: 5px;
                padding-top: 19px;
                line-height: 32px;
                font: {
                    size: 25px;
                    style: italic;
                    weight: 500;
                };
            }
        }
        .article-text {
            padding: 30px;

            ul {
                li {
                    display: inline-block;
                }
            }
            .category {
                a {
                    background: var(--mainColor);
                    color: var(--whiteColor);
                    padding: 3px 15px;
                    display: inline-block;
                    border-radius: 3px;
                    font-weight: 400;
                    margin-right: 10px;
                }
            }
            p {
                margin-bottom: 20px;
            }
            .blockquote {
                background: #f4f4f4;
                padding: 30px;
                font-size: 17px;
                border-left: 4px solid var(--mainColor);
                margin: {
                    bottom: 25px;
                    top: 25px;
                };
                p {
                    color: var(--blackColor);
                }
            }
            .author-share {
                margin-top: 40px;

                .social-share {
                    text-align: right;

                    a {
                        background: var(--mainColor);
                        color: var(--whiteColor);
                        width: 35px;
                        display: inline-block;
                        height: 35px;
                        text-align: center;
                        line-height: 35px;
                        border-radius: 50%;
                        margin-left: 5px;

                        &:hover {
                            background-color: var(--blackColor);
                            color: var(--whiteColor);
                        }
                    }
                }
            }
            .article-author {
                overflow: hidden;

                .author-img {
                    float: left;
                    width: 27%;

                    img {
                        border-radius: 50%;
                        max-width: 100%;
                        width: auto !important;
                    }
                }
                .author-title {
                    float: left;
                    width: 73%;
                    padding-left: 25px;
                    margin-top: 17px;

                    h4 {
                        margin-bottom: 6px;
                        text-transform: uppercase;
                        font: {
                            size: 20px;
                            weight: 500;
                        };
                    }
                    span {
                        color: #666666;
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .post-controls-buttons {
        margin: {
            top: 50px;
            bottom: 50px;
        };
        padding: {
            left: 70px;
            right: 70px;
        };
        div {
            display: inline-block;

            a {
                box-shadow: 0 5px 10px rgba(0,9,128,0.035),0 7px 18px rgba(0,9,128,0.05);
                padding: 10px 20px;
                border-radius: 5px;
                background: var(--whiteColor);
                color: var(--mainColor);
                display: inline-block;

                &:hover {
                    background: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
        .controls-right {
            float: right;
        }
    }
    .post-comments {
        .title {
            margin-bottom: 30px;
        }
        .single-comment {
            box-shadow: 0 5px 10px rgba(0,9,128,0.035),0 7px 18px rgba(0,9,128,0.05);
            padding: 25px;
            margin-bottom: 25px;
            position: relative;
            overflow: hidden;
            background: var(--whiteColor);

            &.left-m {
                margin-left: 30px;
            }
            .comment-img {
                float: left;
                width: 13%;

                img {
                    border-radius: 50%;
                }
            }
            .comment-content {
                float: left;
                width: 87%;
                padding-left: 25px;

                h4 {
                    margin-bottom: 12px;
                    font: {
                        weight: 500;
                        size: 20px;
                    };
                }
                p {
                    margin-bottom: 15px;
                }
                a {
                    position: absolute;
                    right: 25px;
                    bottom: 25px;
                    color: var(--mainColor);
                    text-transform: uppercase;
                    font-size: 17px;
                }
            }
        }
    }
    .leave-a-reply {
        box-shadow: 0 5px 10px rgba(0,9,128,0.035),0 7px 18px rgba(0,9,128,0.05);
        padding: 25px;
        background: var(--whiteColor);
        margin-top: 50px;
        
        .title {
            margin-bottom: 25px;
        }
        .btn {
            margin-top: 0px;
        }
    }

    /*==============================
    Footer Area CSS
    ==============================*/
    .footer-area {
        text-align: left;
        background: #F0F0F0;
        
        h3 {
            line-height: 22px;
            margin-bottom: 30px;

            a {
                color: var(--whiteColor);
                text-transform: uppercase;
                display: inline-block;
                font: {
                    size: 35px;
                    weight: 600;
                };
            }
            span {
                text-transform: lowercase;
                color: var(--mainColor);
                font: {
                    size: 35px;
                    weight: 700;
                };
            }
        }
        ul {
            li {
                display: inline-block;
                margin: {
                    left: 4px;
                    right: 4px;
                };
                a {
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    line-height: 31px;
                    border: 1px solid var(--whiteColor);
                    border-radius: 50%;
                    color: var(--blackColor);
                    font-size: 14px;
                    display: block;

                    &:hover, &:focus {
                        background: var(--mainColor);
                        color: var(--whiteColor);
                        border-color: var(--mainColor);
                    }
                }
            }
        }
        p {

            color: var(--blackColor);

            a {
                color: var(--blackColor);

                &:hover {
                    color: var(--mainColor);
                }
            }
        }
    }

    /*==============================
    Sidebar Eaxmple Demo CSS
    ==============================*/
    .demo-modal-panel {
        position: fixed;
        right: 0;
        top: 40%;
        transform: translateY(-40%);
        z-index: 99998;

        .sidebar-demo-control {
            display: inline-block;
            border: none;
            background-color: var(--blackColor);
            color: var(--whiteColor);
            padding: 0 0;
            letter-spacing: 1px;
            box-shadow: rgba(0, 0, 0, 0.15) 0 0 25px;
            padding: 12px 0 10px;
            transition: var(--transition);
            cursor: pointer;
            text-transform: uppercase;
            border-radius: 5px 0 0 5px;
            font: {
                weight: 600;
                size: 13px;
            };
            span {
                writing-mode: vertical-rl;
                text-orientation: upright;
                padding: {
                    left: 5px;
                    right: 5px;
                };
            }
            &:hover {
                color: var(--whiteColor);
                background-color: var(--mainColor);
            }
        }
    }
    .example-demo-modal {
        position: fixed;
        overflow: hidden;
        right: 0;
        top: 0;
        z-index: 99999;
        transition: .9s;
        opacity: 0;
        visibility: hidden;
        border-left: 1px solid #eeeeee;
        right: -100%;

        .inner {
            width: 450px;
            height: 100vh;
            overflow-y: scroll;
            background-color: var(--whiteColor);
            box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
            padding: 90px 30px 30px 30px;

            ul {
                display: flex;
                flex-wrap: wrap;
                margin: {
                    left: -10px;
                    right: -10px;
                    bottom: -20px;
                };
                li {
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding: {
                        left: 10px;
                        right: 10px;
                        bottom: 20px;
                    };
                    .single-demo {
                        position: relative;
                        text-align: center;
                        border-radius: 5px;

                        img {
                            border-radius: 5px;
                            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
                            border: 5px solid var(--whiteColor);
                        }
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-color: var(--mainColor);
                            transition: var(--transition);
                            opacity: 0;
                            visibility: hidden;
                            border-radius: 5px;
                        }
                        span {
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            z-index: 1;
                            color: var(--whiteColor);
                            text-transform: capitalize;
                            transition: var(--transition);
                            opacity: 0;
                            visibility: hidden;
                            margin-top: 15px;
                            font: {
                                size: 16px;
                                weight: 600;
                            };
                        }
                        .link-btn {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            display: block;
                            z-index: 3;
                        }
                        &:hover {
                            &::before {
                                opacity: 1;
                                visibility: visible;
                            }
                            span {
                                opacity: 1;
                                visibility: visible;
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
        .header-title {
            position: absolute;
            background-color: var(--whiteColor);
            top: -1px;
            left: 0;
            right: 0;
            z-index: 5;
            border-bottom: 1px solid #eeeeee;
            padding: {
                top: 18px;
                bottom: 15px;
                left: 30px;
                right: 30px;
            };
            .example-demo-modal-control {
                position: absolute;
                right: 25px;
                top: 15px;
                font-size: 20px;
                color: var(--blackColor);
                transition: var(--transition);
                background-color: transparent;
                border: none;
                padding: 0;
                display: inline-block;
        
                &:hover {
                    color: var(--mainColor);
                }
            }
            .title {
                color: var(--blackColor);
                margin-bottom: 0;
                font: {
                    size: 18px;
                    weight: 600;
                };
            }
        }
        &.active {
            right: 0;
            opacity: 1;
            visibility: visible;
        }
    }
    .video-popup {
        .overlay {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9991;
            position: fixed;
            background-color: rgba(0, 0, 0, 0.8);
        
            iframe {
                width: 850px;
                height: 475px;
            }
            .nsm-content {
                top: 50%;
                left: 50%;
                width: 850px;
                height: 475px;
                position: absolute;
                transform: translate(-50%, -50%);
                background-color: var(--whiteColor);
            }
        }
        .nsm-dialog-btn-close {
            background-color: transparent;
            color: var(--whiteColor);
            position: absolute;
            border: none;
            right: -30px;
            top: -30px;
        }
    }

    .parrafos {
        z-index: 3;
        display: flex;
        text-align: left;
        padding: 5em;
        padding-left: 0;
    }
    .justify-content-center {
        justify-content: center !important;
    }
    .row {

        display: flex;
        flex-wrap: wrap;

    }
    .welcome-title1{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) var(--unnamed-font-size-60)/var(--unnamed-line-spacing-72) var(--unnamed-font-family-coolvetica);
        letter-spacing: var(--unnamed-character-spacing-1-2);
        color: var(--unnamed-color-0f2b12);
        text-align: left;
        font: normal normal 300 60px/72px Coolvetica;
        letter-spacing: 1.2px;
        color: #0F2B12;
        opacity: 1;
    }
    .battery {
        text-align: center;
        font-family: Coolvetica;
        font-size: 11rem;
        font-weight: 400;
        line-height: 0.8;
        text-transform: uppercase;
        color: #0B1F0D;
        letter-spacing: 4.76px;
        opacity: 0.1;
    }
    .battery-cea {
        text-align: center;
        font-family: Coolvetica;
        font-size: 11rem;
        font-weight: 300;
        line-height: 1.2;
        text-transform: uppercase;
        background-color: #0B1F0D;
        letter-spacing: 4.76px;
    }
    .battery-cea-home {
        text-align: center;
        font-family: Coolvetica;
        font-size: 11rem;
        font-weight: 300;
        line-height: 1.2;
        text-transform: uppercase;
        background-color: #0B1F0D;
        letter-spacing: 4.76px;
    }

    @media (max-width: 768px) {
    /* Tamaño de fuente para pantallas más pequeñas */
    .battery-cea-home {
        font-size: 3rem;    
    }
    }


    .battery-cea-homes {
        text-align: center;
        font-family: Coolvetica;
        font-size: 11rem;
        font-weight: 300;
        line-height: 1.2;
        text-transform: uppercase;
        background-color: #ffffff;
        opacity: 0.1;
        letter-spacing: 4.76px;
    }
    
    .battery span {
        display: block;
    }
    .services-container {
        background-color: #EFE6C0;
    }
    
    .single-services {
        position: relative;
        background-color: #232e1f;

    }
    .single-services-byd {
        position: relative;
        background-color: #E6E8E680;

    }
    .single-services-byd .services-content {
        padding: 30px 25px;

    } 
    .single-services img {
        position: relative;

        z-index: 1;
    }
    
    .single-services .text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #EFE6C0;
        z-index: 2;
        display: none;
        padding: 10px;
        box-sizing: border-box;
    }
    
    .single-services:hover .text {
        display: block;
    }
    .services-container {
        display: flex;
        justify-content: center;
    
        .single-services-bat {
        flex: 1;
        }
    }
    .single-services-byd img {
        position: relative;

        z-index: 1;
    }
    
    .single-services-byd .text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #EFE6C0;
        z-index: 1;
        display: none;
        padding: 10px;
        box-sizing: border-box;
    }
    
    .single-services-byd:hover .text {
        display: block;
        text-align: left;
    }
    .services-container {
        display: flex;
        justify-content: center;
    
        .single-services-byd-bat {
        flex: 1;
        }
    }

    .border-main {
        border: 1px solid var(--main);
    }
    .main-banner-imagen {
        background: url("/assets/img/WeAreCea.jpg") no-repeat center center fixed;
        background-size: cover;
        height: 100vh; /* establece la altura de la imagen de fondo en el 100% del viewport */
    }
    .main-banner-homes {
        background-size: cover;
        height: 100vh; /* establece la altura de la imagen de fondo en el 100% del viewport */
    }
    .main-banner-cont {
        background: url("/assets/img/Contact.jpg") no-repeat center center fixed;
        background-size: cover;
        height: 100vh; /* establece la altura de la imagen de fondo en el 100% del viewport */
    }
    .button-container {
        display: flex;
        align-items: center;
        margin-top: 10em; /* Espacio entre el botón y el texto */
    }
    .small-padd{
        padding: 100px;
    }
    .form-container {
        display: flex;
    }
    
    .form-left {
        flex: 1;
        padding-right: 20px;
    }
    
    .form-right {
        flex: 1;
        padding-left: 20px;
    }
    
    .form-group {
        margin-bottom: 15px;
    }
    
    label {
        display: block;
        margin-bottom: 5px;
    }
    
    input[type="text"],
    input[type="email"],
    textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid #ffffff;
        background-color: #DF280D;
    }
    
    textarea {
        height: 150px;
    }
    
    .btn-form {
        margin-top: 15px;
        color: #fff;
        padding: none;
        border: none;
        cursor: pointer;
        color: var(--whiteColor);
        background-color: var(--mainColor);
        border-color: var(--mainColor);
        position: static;
        margin-left: 0;
        top: 60%;
        transform: translateY(-50%);
        
        
        &:hover, &:focus, &.disabled:hover, &.disabled:focus {
            color: var(--mainColor);
            background-color: var(--whiteColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
        }
        
        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
        
        &.focus, &:focus {
            box-shadow: none;
        }
        
        &.disabled, &:disabled {
            color: var(--whiteColor);
            background-color: var(--mainColor);
            border-color: var(--mainColor);
            opacity: 1;
        }
    }
    
    .btn-form:hover {
        background-color: #000000;
        border-color: var(--mainColor);

    }
    form {
        margin: 0 auto;
        padding: 20px;
        background-color: transparent;
        border: none;
        border-radius: 5px;
    }
    .form-title {
    text-align: left;
    }

    h1 {
        text-align: center;
       
    }
    

    
    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }
    
    .input-group {
        position: relative;
    }
    
    .input-group input,
    .input-group textarea {
        width: 100%;
        border: 1px solid #3a9b43;
        padding: 10px;
    }
    
    .btn-primary {
        display: block;
        padding: 10px;
        background-color: white;
        color: #000000;
        text-align: center;
        border-color: #ffffff;
        overflow: hidden;
        position: relative;
        z-index: 1;
        
    }
    
    .btn-primary::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;  
        background-color: #000000;
        z-index: -1;
    }
    
    .btn-primary:hover::before {
        height: 100%;
    }

    
    
    
    .texto-crema{
        color: #000000;
    }
    .texto-blanco{
        color: #ffffff;

    }
    .texto-negro{
        color: black;
    }
    .borders-top {
        
        border-top: solid #3A9B43;
        border-top-width: 1px; 
        padding-top: 5px;

    }
    
    .borders-bottom {
        
        display: inline-block;
        border-bottom: solid #3A9B43;
        border-bottom-width: 1px; 
        padding-bottom: 15px;

    }
    .borders-bottom-red {
        
        display: inline-block;
        border-bottom: solid red;
        border-bottom-width: 1px; 
        padding-bottom: 15px;

    }
    .borders-bottom-green {
        
        display: inline-block;
        border-bottom: solid #3A9B43;
        border-bottom-width: 1px; 
        padding-bottom: 15px;

    }
    .trasp{
        color: transparent !important;
    }

    
    /* Estilos para pantallas grandes */
    @media screen and (min-width: 992px) {
        .welcome-title1 {
            width: 50%;
        }
        .welcome-title2 {
            width: 50%;
        }
    }

    /* Estilos para pantallas medianas */
    @media screen and (max-width: 991px) {
        .welcome-title1,
        .welcome-title2 {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        /* Tamaño de fuente para pantallas más pequeñas */
        .battery {
        font-size: 3rem;
        }
        .parrafos {
            z-index: 3;
            display: block; 
            text-align: left;
            padding: 5em;
            padding-left: 0;
            margin-left: 60px;
        }
        .main-banner-ci{
            height: 70rem;
    
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
                color: #0B1F0D;
            };      
            .btn-second {
                float: left;
            }
    
            &.gradient-bg {        
                &::before {
                    background: #0B1F0D;
                    opacity: .80;
                }
            }
        }
        .main-banner-home-ci{
            height: 400px;
    
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
                color: #0B1F0D;
            };      
            .btn-second {
                float: left;
            }
    
            &.gradient-bg {        
                &::before {
                    background: #0B1F0D;
                    opacity: .80;
                }
            }
        }
    }
    @media (max-width: 768px) {
        /* Tamaño de fuente para pantallas más pequeñas */
        .battery {
        font-size: 3rem;
        }
    }
    .link-btn {
        display: inline-block;
        /* Otros estilos del enlace */
    }
    
    .link-btn:hover {
        border-bottom: 2px solid var(--mainColor); /* Reemplaza "red" con el color deseado */
    }
    .texto-secciones{
        font-size: large;
    }
    .btn-right{
        float: right;   
    }
    .navbar-nav .nav-item a {
        position: relative;
        text-decoration: none;
        color: black;
        padding: 20px 5px;
        font-size: 14px;

        transition: color 0.3s ease-in-out;
      }
      
      .navbar-nav .nav-item a::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #000000;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s ease-in-out;
      }
      
      .navbar-nav .nav-item.active a::after,
      .navbar-nav .nav-item a:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
      .navbar-nav .nav-item.active a,
      .navbar-nav .nav-item a:hover {
        color: #000000; /* Cambio de color del texto al hacer clic o al pasar el cursor */
      }
    .navbar-nav .nav-items a {
        position: relative;
        text-decoration: none;
        color: black;
        padding: 20px 5px;
        font-size: 14px;
        justify-content: center;
        transition: color 0.3s ease-in-out;
      }
      
      .navbar-nav .nav-items a::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ff0000;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s ease-in-out;
      }
      
      .navbar-nav .nav-items.active a::after,
      .navbar-nav .nav-items a:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
      .navbar-nav .nav-items.active a,
      .navbar-nav .nav-items a:hover {
        color: #000000; /* Cambio de color del texto al hacer clic o al pasar el cursor */
        transform: translateY(-2px); /* Levantar el texto 2px para compensar el resaltado */
      }
      .navbar-nav{
        margin-left: em;
      }

    .text-crema{
        color: #000000;
    }
    .online-margin{
        margin-top: 1.5em;
        justify-content: center; /* Centrar horizontalmente */
        align-items: center;
    }
    .menuCSS3{
        z-index: 999;
    }
    .menuCSS3 ul {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .menuCSS3 a {
        display: block;
        background-color: #F9B53C;
        text-decoration: none;
        color: #191C26;
        padding: 1em;
    }
    .menuCSS3 a:hover {
        background-color: #CC673B;
    }
    .menuCSS3 ul li ul {
        display: none;
    }
    .menuCSS3 ul li a:hover + ul, .menuCSS3 ul li ul:hover {
        display: block;
    }
    .alert {
        padding: 0%;
    }
    .map {
        width: 400px;
        height: 400px;
    }
    .fuente{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-poppins);
    }
    .container-img{
        max-width: 100%;


        
    }

    $font-poppins-light: 'Poppins Light', sans-serif;
    .fuente-pop {
        font-family: $font-poppins-light;
    }
    $font-poppins-regular: 'Poppins, Regular', sans-serif;
    .fuente-pop-reg {
        font-family: $font-poppins-regular;
        font-weight: 300;
    }
    $font-poppins-regular: 'Coolvetica, Light', sans-serif;
    .fuente-cool-light {
        font-family: $font-poppins-regular;
        font-weight: 300;
    }
    .container-imge {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    
    .container-imge img {
        width: 100%;
        height: auto;
    }
    @media (max-width: 576px) {
        h1.mobile-font-size {
        font-size: 60px;
        line-height: 1.5em;
        }
        h1.mobile-font-size-sm {
        font-size: 40px;
        line-height: 1.5em;
        margin-top:3em;

        }
        h1.mobile-font-size-raion {
        font-size: 50px;
        line-height: 1.5em;
        margin-top:10em;
        

        }
    }
    h1.pc-font-size {
        font-size: 75px;
        margin-top:3em;
        line-height: 1em;
    }
    h1.pc-font-size-mt {
        font-size: 75px;
        margin-top:2em;
        line-height: 1em;
    }
    @media (max-width: 768px) {
        /* Tamaño de fuente para pantallas más pequeñas */
        .battery-cea-homes {
        font-size: 3rem;    
        }
        .what-cea {
            font-size: 14px;
        }
    }
    .what-cea {
        font-size: 50px;
    }
    .img-what{
        height: 400px;
    }

    @media (max-width: 768px) {

        .what-cea {
            font-size: 30px;
            line-height: 20px;

        }
        .img-what{
            height: 180px;
        }
        .titulocea{
            margin-bottom: 0px;
        }
        #infoBox {
            width: 40px;
            height: 40px; 
            font-size: 12px; 
        }

        .economy-text{
            justify-content: left;
        }

    }

    #infoBox {
        position: absolute;
        bottom: 30px;
        left: 20px;
        width: 300px;
        height: 200px;
        background-color: white;
        padding: 10px;
        border: none;
        z-index: 1;
    }
    
    @media screen and (max-width: 767px) {
        #infoBox {
        width: 300px;
        height: 100px;
        font-size: 8px;
        overflow: auto;
        padding: 10px 20px; 
        }
    }
    
    .nav {
        list-style: none;
        padding-left: 20px;
        margin: 0;
      }
      .idioma{
        text-align: center;

        padding-right: 10px !important;
        padding-left: 0px !important;
      }
      
      .nav-item {
        position: relative; 
      }
      
      .menu-item {
        position: relative;
      }
      
      .has-submenu:hover .submenu {
        display: block;
      }
      
      .submenu {
        display: none;
        position: absolute;
        top: 100%; /* Mostrar debajo del enlace */
        left: 0;
        background-color: #ffffff;
        border: none;
        list-style: none;
        padding: 0;
        width: max-content;
      }
      
      .submenu li {
        margin-top: 10px;
    }
      
      .submenu li a {
        padding: 10px 20px;
        color: black;
        text-decoration: none;
        transition: background-color 0.3s ease-in-out;
      }
      
      .submenu li a:hover {
        background-color: #ddd;
        
      }
      
      .menu-item:hover .submenu.active,
      .menu-item.active .submenu {
        display: block; /* Cambiar a "block" para mostrar las opciones en una lista vertical */
      }
      .menu-item:hover .submenu,
    .menu-item.active .submenu,
    .submenu-clicked {
        display: block;
        }
        .submenu li a {
            display: block;
            padding: 5px 20px;
            font-size: 14px; /* Ajusta el tamaño de la letra según lo desees */
            color: black;
            text-decoration: none;
            transition: background-color 0.3s ease-in-out;
          }
          

    .KNOW-HOW{
    color: #ffffff;
    }
    .card-ci {
        display: block;
        margin-bottom: 15px;
        border: none;
        
    
        .card-header-ci {
            padding: 30px;
            margin-bottom: 10;
    
            &:first-child {
                border-radius: 0;
            }
            a {
                display: block;
    
                &:hover {
                    background: var(--mainColor);
                }
                h5 {
                    overflow: hidden;
                    padding: 20px 40px 20px 20px;
                    color: var(--whiteColor);
                    background: var(--mainColor);
                    position: relative;
                    font: {
                        size: 16px;
                        weight: 500;
                    };
                }
                i {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    font-size: 16px;
                    transform: translateY(-50%) rotate(180deg);
                }
                &.collapsed {
                    h5 {
                        color: var(--blackColor);
                        background: transparent;
                        transition: var(--transition);
        
                        &:hover {
                            color: var(--whiteColor);
                        }
                    }
                    i {
                        transform: translateY(-50%) rotate(0);
                    }
                }
            }
        }
        .card-body {
            line-height: 122.9px;
        }
    }
    .card-dow {
        display: block;
        border-bottom: solid 1px #0000001b;

        
        
    
        .card-header-dow {
            padding: none;
            margin-bottom: 10;
    
            &:first-child {
                border-radius: 0;
            }
            a {
                display: block;
    
                &:hover {
                    background: var(--mainColor);
                }
                h5 {
                    overflow: hidden;
                    padding: 20px 40px 20px 20px;
                    color: var(--whiteColor);
                    background: var(--mainColor);
                    position: relative;
                    font: {
                        size: 16px;
                        weight: 500;
                    };
                }
                i {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    font-size: 16px;
                    transform: translateY(-50%) rotate(180deg);
                }
                &.collapsed {
                    h5 {
                        color: var(--blackColor);
                        background: transparent;
                        transition: var(--transition);
        
                        &:hover {
                            color: var(--whiteColor);
                        }
                    }
                    i {
                        transform: translateY(-50%) rotate(0);
                    }
                }
            }
        }
        .card-body {
            line-height: 122.9px;
        }
    }
    .ico-pad{
        padding: 2em;
    }
    .container-ci{
        justify-content: center;

    }
    .container-about{
        justify-content: center;

    }
    .icon-border{
        border: none;
        border-color: #3a9b43;
        padding-bottom: 2em;
        background-color: #F0F0F0;
    }
    .boton-ci{
        margin-top: 5em;
    }
    @media (max-width: 767px) {
        .orden1{
            order: 1;
        }
        .orden2{
            order: 2;
        }
        .input-with-button input {
          width: 100px !important; /* Ajusta la altura en pantallas pequeñas */
          padding-left: 0% !important;

        }

        .btn-resp{
            height: 22px !important;
            width: 7px !important;
            font-size: 15px !important;
            padding: 0px !important;
            padding-right: 3em !important;

        }
        .form-control-about{
            height: 18px !important;
            width: 15px !important;
          }
        .nav-letra{
            font-size: 15px !important;
        }
        .main-banner-white-service {
            h1{
                font-size: large !important;
                padding-top: 2em !important;
            }
            p{
                font-size: 15px !important;
                padding-bottom: 3em !important;

            }
            height: auto !important;
            position: relative;
            overflow: hidden;
    
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
                color: #F8F8F8;
            };
    
        }
        .img-resp-prod{
            margin-top: 10px !important;
            height: auto !important;
        }
        .resp-ico{
            font-size: 10px !important;
        }
        .cuadrado-about {
            border: solid 0.5px white;
            border-radius: 3px;
            margin-right: 3em !important;
            padding-bottom: 0em !important; /* Ajusta el valor según tu preferencia */
            padding-top: 0em !important; /* Ajusta el valor según tu preferencia */
        }
        .byd-about{
            margin-top:15em !important;
        }
        .cont-resp{
            margin-left: 0px !important;
        }
        .pad-card{
            padding-top: 1.8em !important;
        }
        .card-about{
            p{
                font-size: 15px !important;
            }
        }
        .padding-about{
            padding-top: 0% !important;

        }
        .main-banner {
            h1{
                font-size: large !important;
            }
            li{
                font-size: 15px !important;
            }
            height: 700px !important;
            position: relative;
            overflow: hidden;
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
                color: #0B1F0D;
            };
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
    
    
            }
            &.gradient-bg {        
                &::before {
                    background: linear-gradient(90deg, #232021 0, #0093c8);
                    opacity: .80;
                }
            }
        }
        .main-banner-white-about {
            margin-top: 2em;
            h1{
                font-size: large !important;
            }
            p{
                font-size: 15px !important;
            }
            height: 300px !important;
            position: relative;
            overflow: hidden;
            padding-left: 20%;
            padding-right: 20%;
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
                color: #ffffff;
            };
    
        }
        .mt-resp{
            margin-bottom: 100px !important;
        }
        .main-banner-white-home{
            h5{
                font-size: large !important;
            }
            p{
                font-size: 20px !important;
            }
        }
        .banner-if{
            h1{
                font-size: large !important;
            }
        }
        .main-banner-red {
            p{
                font-size:15px !important;
            }
            h1{
                font-size:20px !important;
            }
            height: 100px;
            position: relative;
            overflow: hidden;
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
                color: #DF280D;
            };
            
        }
        .banner-home-different{
            height: 550px;
            h1{
                font-size: large !important;
            }

        }
        .main-banner-home {
            h1{
                font-size: x-large !important;
            }
            img{
                height: 30px;
            }
            margin-top: 4em;
            height: 43rem;
    
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
                image: url('/assets/eft/fotos/HOME/Home1.jpg');
            };      
            .btn-second {
                float: left;
            }
    
            &.gradient-bg {        
                &::before {
                    background: #0B1F0D;
                    opacity: .80;
                }
            }
        }
        .banner-home-text {
        height: 500px !important;

            p{
                font-size: 15px !important;
            }
            a{
                font-size: 12px !important;
            }
        }
        .main-banner-white-home-1 {
            height: 700px;
            position: relative;
            overflow: hidden;
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
                color: #ffffff;
            };
            padding-top: 0px !important;
            h1{
                font-size: large !important;
            }
            
            p{
                font-size: 15px !important;
            }
    
        }
    
        
        .container-service{
            height: auto !important;
        }
        .no-padding{
            padding: 0 !important;
        }
        .main-banner-service-img{
            padding-top: 3em;
            height: 950px !important;
            h1{
                font-size: large !important;
            }
            h6{
                font-size: large !important;
            }
            small{
                font-size: 15px !important;
            }
        }
        .panel-rojo{height: 150px !important;
            p{
                font-size: 18px !important;
            }
        }
        .main-banner-products{
            height:850px !important;
            width: 100%;
            h1{
                font-size: large !important;
            }
            p{
                font-size: 15px !important;
            }
        }
        .main-banner-products-img{
            height: auto !important;
        }
        .container-resp{
            padding-left: 10% !important;
            padding-right: 10% !important;
        }
        .padding-none{
            padding-top: 0 !important;
        }
        
        .main-banner-white-products{
            height: 780px !important;
        }
        .img-products-rep{
            width:auto !important;
            height:300px !important;
        }
        .padding-top-resp{
            padding-top: 20px !important;
        }
        .input-with-button input,
        .input-with-button button {
          height: 40px; /* Ajusta la altura en pantallas pequeñas */
        }
      
        .main-banner-about-img {
            height: auto;
            width:100%;
            position: relative;
            overflow: hidden;
            background: white;

            padding-top: 2em;        
    
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
    
    
            }
            &.gradient-bg {        
                &::before {
                    background: linear-gradient(90deg, #232021 0, #0093c8);
                    opacity: .80;
                }
            }
        }
        .padding-top{
            padding-top: 2em;
    }

        .titulo-chico{
            font-size:12px;
            margin-top: 5px;
        }
        .texto-centrado-h1{
            padding-left: 0 !important;
            padding-right: 0 !important;
                }
        .card-header-ci {
            padding: 20px; /* Reducir el padding para pantallas más pequeñas */
            font-size: 14px; /* Reducir el tamaño de fuente para pantallas más pequeñas */
        }
    
        .ico-pad {
            padding: 0.5em; /* Ajustar el padding para íconos en pantallas más pequeñas */
        }
        .main-banner-text-ci { 
            height: 19em;
            position: relative;
            text-align: center;
            z-index: 1;
            
    
            h4 {
                color: var(--whiteColor);
                font: {
                    weight: 400;
                    size: 20px;
                };
            }
            h2{
                color: var(--whiteColor);
                margin: {
                    top: 30px;
                    bottom: 30px;
                };
                font: {
                    weight: 142;
                    size: 35px;
                };
                span {
                    color: #ff2c57;
                    font-weight: 600;
                }
                ngx-typed-js {
                    display: inline-block;
    
                    div {
                        display: inline-block;
    
                        span {
                            display: inline-block;
                        }
                    }
                }
            }
            h1 {
                color: var(--whiteColor);
                margin: {
                    top: 30px;
                    bottom: 0px;
                };
                font: {
                    weight: 500;
                    size: 75px;
                };
                span {
                    color: #ff2c57;
                    font-weight: 600;
                }
                ngx-typed-js {
                    display: inline-block;
    
                    div {
                        display: inline-block;
    
                        span {
                            display: inline-block;
                        }
                    }
                }
            }
            p {
                color: var(--whiteColor);
                max-width: 650px;
                font-size: 15.5px;
                margin: {
                    left: auto;
                    right: auto;
                    bottom: 30px;
                };
            }
            .btn {
                margin: {
                    left: 7px;
                    right: 7px;
                };
                &:hover, &:focus {
                    border-color: var(--whiteColor);
                }
            }
            .view-work {
                background: transparent;
                border-color: var(--whiteColor);
    
                &:hover, &:focus {
                    border-color: var(--mainColor);
                    background: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
            &.text-start {
                p {
                    margin: {
                        left: 0;
                        right: 0;
                    };
                }
            }
            &.text-end {
                p {
                    margin: {
                        left: auto;
                        right: 0;
                    };
                }
            }
        }
        .main-banner{
            padding-top: 0px;
        }
        .space-img{
            margin-bottom: 5px;
        }
        .no-wrap-resp{
                font-size: 14px; 
                white-space: normal;
        }

        .size-img-resp img {
            height: 100%; /* Mantiene la proporción de aspecto original */
            width: 100%; /* Ajusta el ancho de las imágenes */
            margin: 0 auto; /* Centra las imágenes horizontalmente */
        }
    
        .col-md-3 {
            flex: 0 0 50%; /* Divide las columnas en 2 filas en dispositivos móviles */
            max-width: 100%; /* Asegura que cada columna ocupe la mitad del ancho del contenedor */
        }
        .img-rion{
            height: 700px;
        }
        .container-img-resp{
            width: 550px;
            height: 300px;
            max-width: 100%;
    
            
        }
        .container-img-resp-2{
            width: 550px;
            height: 350px;
            max-width: 100%;
    
            
        }
        .container-img-resp-1{
            width: 550px;
            height: 250px;
            max-width: 100%;
    
            
        }

        
        .fecha {
        text-align: right; /* Alineación a la derecha de la fecha en pantallas pequeñas */
        }
    }
  
    
    .sizeimg{
        width: 70%
    }
    .img-rion{
        height: 700px;
        width:25em;
    }
    .resp-mt-2{
        margin-top: 2em;
    }
    .container-img-bmu{
            width: 550px;
            height: 350px;
            max-width: 100%;
    
    }

.texto-centrado{
    line-height: 1.5; /* Ajusta el espaciado entre líneas */
    font-size: 22px; 
    padding-top:15%;
    padding-left: 5%;
    padding-right: 5%;
    font-weight: lighter !important;

}
.texto-centrado-a{
    line-height: 1.5; /* Ajusta el espaciado entre líneas */
    font-size: 16px; 


        &:hover, &:focus {
            color: var(--whiteColor);
            text-decoration: none;
        }
    }

    .texto-centrado-h1{
        padding-right:13%;
        padding-left:13%;

    }
    .lista {
        border-bottom: 1px solid var(--blackColor);

    }
    hr {
        border-color: black;
        opacity: 1;
      }

    .nav-about{
        display: flex; /* Utilizamos flexbox para alinear los elementos en una fila */
        align-items: center; /* Centramos verticalmente los elementos */
        justify-content: center; /* Centramos horizontalmente los elementos */
        border-top: solid 1px #00000010;
    }
    .main-banner {
        background: linear-gradient(271deg, #72110c,#DF280D);
    }
    
    



    .main-banner .row {
        display: flex;
    }


    .main-banner img {
        max-width: 100%; 

    }
     .main-banners img {
         max-width: 100%; 
         height: 350px;
     }
    .main-banner-battery {
        height: auto;

        background: white;
        padding-left: calc(var(--bs-gutter-x) * 0);
    }
    .main-banner-products {
        height: auto;

        background: white;
        padding-left: calc(var(--bs-gutter-x) * 0);
    }
    .main-banner-products-img {
        height: auto;

        background: white;
        padding-left: calc(var(--bs-gutter-x) * 0);
    }
    .main-banner-service-img {
        height: 700px;

        background: white;
        padding-left: calc(var(--bs-gutter-x) * 0);
    }
    

    .main-banner-battery .row {
        display: flex;
    }


    .main-banner-battery img {
        max-width: 100%; /* Aseguramos que la imagen no sea más ancha que su contenedor */
        height: auto; /* Mantenemos la proporción de aspecto original */
    }
    

.row.no-gutter {
    margin-left: 0;
    margin-right: 0;
  }
  
  .row.no-gutter [class*='col-']:not(:first-child),
  .row.no-gutter [class*='col-']:not(:last-child) {
    padding-right: 0;
    padding-left: 0;
  }
  .pading-about{
    padding-right: 1em;
  }
  .cuadrado-about {
    border: solid 0.5px white;
    border-radius: 3px;
    margin-right: 3em;
    padding-bottom: 1em; /* Ajusta el valor según tu preferencia */
    padding-top: 1em; /* Ajusta el valor según tu preferencia */
}
.input-with-button {
    display: flex;
  }
  

  
  .btn-no{
    border-radius: 0
  }
  .btn-nopadding{
    padding-left: 0;
}
  .form-control-about{
    height: 48px;
    width: 10px;
  }
  .svg-small {
    width: 30px; /* Ajusta el tamaño según tus preferencias */
    height: auto; /* Mantiene la proporción del SVG */
  }
  .svg-med {
    width: 5em; /* Ajusta el tamaño según tus preferencias */
    height: auto; /* Mantiene la proporción del SVG */
  }
  .custom-hr {
    border: none;
    height: 1px; /* Ajusta la altura de la línea horizontal */
    background-color: black; /* Cambia el color de la línea según tus preferencias */
    margin: 10px 0; /* Espacio alrededor de la línea */
    width: 30px;
}
.expand-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .expand-content {
    display: none;
    background-color: #f9f9f9;
    padding: 10px;
  }
  
  .expand-content a {
    display: block;
    margin-bottom: 5px;
    text-decoration: none;
    color: black;
  }
  /* home-eight.component.scss */

/* Quitar bordes de las tarjetas */
.card {
    border: none;
  }
  
  /* Quitar bordes de los botones dentro de las tarjetas */
  .card .btn {
    border: none;
  }
  
  /* Quitar bordes de los botones cuando están enfocados */
  .btn:focus,
  .btn.focus {
    box-shadow: none;
  }
  .nomargin p{
    margin: none;
  }
  .hover a {
    color: black; /* Color de texto negro por defecto */
    opacity: 0.6; /* Opacidad por defecto */
    font-weight: bold; /* Texto en negrita por defecto */
    transition: opacity 0.3s; /* Transición suave para la opacidad */
}

.hover a:hover {
    opacity: 1; /* Opacidad al pasar el cursor por encima */
    /* Puedes añadir otros estilos aquí si lo deseas */
}
.container-video {
    border-radius: 15px; /* Agrega esquinas redondeadas */
    overflow: hidden; /* Asegura que el contenido no se desborde */
}
.nav-about {
    background-color: #FFFFFF;
    height: 40px;
    width: 100%;
    transition: top 0.3s; /* Agrega una transición suave para el cambio de posición */
    display: flex;
    align-items: center;
  }
.nav-service {
    background-color: #FFFFFF;
    height: 40px;
    width: 100%;
    transition: top 0.3s; /* Agrega una transición suave para el cambio de posición */
    display: flex;
    align-items: center;
  }
.nav-fixed {
  position: fixed;
  top: 70px; /* Ajusta la posición vertical en la que quieres que la barra se vuelva fija */
  z-index: 998;
  transition: top 0.3s ease; /* Agrega una transición suave a la propiedad top */

}

  .nav-about p {
    margin: 0;
    display: inline-block;
  }
  .nav-about a {
    
    text-decoration: none;
    color: black;
    font-size: smaller;
    padding: 10px 15px;
    display: block;
    
  }
  .scroll-to-top {
    z-index: 9999;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: transparent !important;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  .hidden {
    display: none;
  }
.top-bar{
    background-color: #000000 !important;
}
.bottom-bar{
    background-color: #000000 !important;
}
.middle-bar{
    background-color: #000000 !important;
}
.dot-list {
    list-style: none;
    padding-left: 0;
}

.dot-list li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem; /* Ajusta el espacio vertical entre elementos de lista */
}

.dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffffff; /* Color de los puntos */
    margin-right: 8px; /* Espacio entre el punto y el texto */
    margin-top: 6px; /* Alinea el punto verticalmente */
}
.carousel-control-prev, .carousel-control-next{
    color: #000000 !important;
}
.carousel-arrow {
    font-size: 24px; /* Tamaño de fuente */
    color: #000; /* Color del símbolo */
  }
  .card-height{
    height: 18em;
  }